import IconRightArrow from "@assets/icons/right-arrow";
import { cn } from "utils/helpers";
import type { ButtonPrimaryProps } from "utils/types";

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  children,
  inline,
  onClick,
  disabled = false,
}) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className="transition-all ease-in duration-300 text-sovrun-pink cursor-pointer text-[1rem] flex relative flex-none group w-full"
  >
    <div className="gap-[1rem] items-center py-[1.5rem] px-[2rem] flex relative z-10 h-[4.3125rem] w-[calc(100%-4.375rem)] md:w-auto">
      <div className="z-20 font-ibm-plex-mono font-semibold relative overflow-hidden">
        <div className="transition-transform ease-in duration-300 uppercase group-hover:translate-y-[-1.25rem] [text-shadow:0px_1.25rem_0px_currentColor] font-ibm-plex-mono font-semibold text-base">
          {children}
        </div>
      </div>
      <div
        className={cn(
          "bg-white rounded-l-[.5rem] transition-all ease-in duration-300 z-0 w-full absolute inset-0 group-hover:rounded-tr-[.5rem] group-hover:rounded-br-[.5rem]",
          {
            "rounded-tl-[.5rem] rounded-bl-[.5em]":
              !inline || inline !== "right",
            "md:rounded-l-none": inline === "right",
          }
        )}
      ></div>
    </div>
    <div className="z-10 gap-[1rem] text-black items-center p-[1.5rem] flex relative left-[-1px] overflow-hidden h-[4.3125rem]">
      <div className="transition-all ease-in duration-300 z-30 h-[1.25rem] flex relative group-hover:translate-x-[0.5rem] translate-x-[-1rem]">
        <div className="w-full h-full flex before:table after:table after:clear-both">
          <IconRightArrow width={"1.375rem"} />
        </div>
      </div>
      <div className="bg-white transition-all ease-in duration-300 w-[60%] absolute inset-[0%_auto_0%_0%] group-hover:translate-x-[0.5rem] translate-x-[-1rem] group-hover:rounded-tl-[.5rem] group-hover:rounded-bl-[.5rem]"></div>
      <div className="w-[70%] h-[60%] flex absolute inset-[auto_0%_0%_auto] overflow-hidden">
        <div className="bg-white w-full h-[140%] relative bottom-[20%] right-[10%] rotate-[50deg]"></div>
      </div>
      <div className="bg-white rounded-tr-[.5rem] w-[65%] h-[60%] absolute inset-[0%_0%_auto_auto]"></div>
    </div>
    <div className="w-[60%] transition-opacity ease-in duration-300 z-0 opacity-0 h-[90%] absolute inset-[5%_0%_0%_5%] shadow-[0_0_5rem_#FFF3]"></div>
    <div className="w-[15%] transition-opacity ease-in duration-300 z-0 opacity-0 h-[90%] absolute inset-[5%_5%_0%_auto] shadow-[0_0_5rem_#FFF3]"></div>
  </button>
);
export default ButtonPrimary;
