import { cn } from "utils/helpers";

interface BasicLayoutFooterProps {
  fixed?: boolean;
}

const BasicLayoutFooter: React.FC<BasicLayoutFooterProps> = ({
  fixed = false,
}) => (
  <footer
    className={cn(`bg-[#131722]`, {
      "md:fixed md:bottom-0 w-full": fixed,
      "mt-40": !fixed,
    })}
  >
    <div className="container max-w-5xl px-4 md:px-6 mx-auto undefined">
      <div className="flex items-center justify-between py-4">
        <span className="font-ibm-plex-mono text-[#9499A3] text-sm uppercase font-semibold">
          <span className="font-light">&copy;</span> 2024 Sovrun
        </span>
        <span className="font-ibm-plex-mono text-[#637282] text-xs font-semibold">
          All Rights Reserved.
        </span>
      </div>
    </div>
  </footer>
);

export default BasicLayoutFooter;
