import "./App.css";
import { adjustFontSize } from "utils/helpers";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "pages/Home";
import PrivacyPolicy from "pages/PrivacyPolicy";

export default function App() {
  const handleResize = (): void => {
    adjustFontSize(); // Adjust font size based on the current viewport width
    // No need to set fontSize in state, since adjustFontSize handles the styling directly
  };

  useEffect(() => {
    // Adjust font size on initial load
    handleResize();

    // Recalculate font size when the window is resized
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />{" "}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />{" "}
      </Routes>
    </Router>
  );
}
