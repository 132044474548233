import IconCheck from "@assets/icons/check";

interface CheckboxProps {
  checked: boolean;
  onChange: () => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange }) => (
  <div
    className="w-[1.5rem] h-[1.5rem] relative cursor-pointer"
    onClick={onChange}
  >
    <input
      type="checkbox"
      className="peer absolute left-0 top-0 z-10 h-full w-full opacity-0 cursor-pointer"
      id="custom-checkbox"
      checked={checked}
      onChange={onChange}
    />
    <div className="w-full h-full border-2 border-sovrun-pink bg-transparent rounded peer-checked:bg-sovrun-pink relative z-0 peer-checked:[&>div]:flex">
      <div className="absolute inset-0 items-center justify-center z-20 hidden">
        <IconCheck />
      </div>
    </div>
  </div>
);

export default Checkbox;
