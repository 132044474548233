import BasicLayout from "layouts/BasicLayout";

const PrivacyPolicy = () => {
  return (
    <BasicLayout>
      <div className="relative mb-8 w-full">
        <div className="container max-w-5xl px-4 md:px-6 mx-auto">
          <div className="relative mb-8 overflow-x-clip">
            <div className="relative py-12 flex flex-col justify-center items-center gap-2">
              <h1 className="font-display font-extrabold text-[2.5rem] leading-[1.1] uppercase text-center font-big-shoulders-display">
                Privacy Policy
              </h1>
              <p className="text-base text-[#9499A3] font-semibold text-center">
                October 2024
              </p>
            </div>
          </div>
        </div>

        {/* SVG Elements and Decorative Images */}
        <svg
          width="415"
          height="195"
          viewBox="0 0 415 195"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="block lg:hidden absolute top-0 left-0 w-full h-full"
          preserveAspectRatio="none"
        >
          <path
            d="M415 1L305.535 1L299.901 18.5005L40.5662 18.5005L34.6102 37.0002L2.31185e-09 37.0002"
            stroke="#131722"
          ></path>
          <path
            d="M415 158L305.535 158L299.901 175.5L40.5662 175.5L34.6102 194L2.31185e-09 194"
            stroke="#131722"
          ></path>
        </svg>

        <svg
          width="1281"
          height="197"
          viewBox="0 0 1281 197"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="hidden lg:block absolute top-0 left-0 w-full h-full"
          preserveAspectRatio="none"
        >
          <path
            d="M1280 2L940 2L922.5 19.5005L117 19.5005L98.5 38.0002L-9 38.0002"
            stroke="#131722"
          ></path>
          <path
            d="M1280 159L940 159L922.5 176.5L117 176.5L98.5 195L-9 195"
            stroke="#131722"
          ></path>
        </svg>

        <img
          src="/images/glass-cube.png"
          alt=""
          aria-hidden="true"
          className="absolute top-[-50px] right-[-35px] xl:top-[-120px] xl:right-[-30px] w-[123px] xl:w-[257px] aspect-square z-10 blur-sm pointer-events-none"
        />
        <img
          src="/images/glass-star.png"
          alt=""
          aria-hidden="true"
          className="absolute bottom-[-35px] left-[-50px] xl:bottom-[-130px] xl:left-[-144px] w-[116px] xl:w-[358px] aspect-square z-10 pointer-events-none"
        />
      </div>

      <div className="container max-w-5xl px-4 md:px-6 mx-auto prose">
        <p>
          This is the privacy policy and privacy notice (the "Privacy Policy")
          of the website located at{" "}
          <a href="https://sovrun.org">https://sovrun.org</a> (the "Site"), a
          copyrighted work belonging to the Sovrun team and its associated legal
          entities, affiliates and related persons, as may be applicable (the
          "Company", "us", "our", and "we"), and includes certain functions,
          features and other services accessible or offered through the Site
          which is associated with Sovrun, previously known as BreederDAO, a
          dual execution layer blockchain designed to infinitely scale on-chain
          games and Autonomous Worlds (AW) (the "Services").
        </p>
        <p>
          This Privacy Policy applies to all personal data that we hold about
          you as well as any personal data you may provide to us in relation to
          your rights and obligations regarding the same, in compliance with the
          British Virgin Island's Data Protection Act, 2021 (DPA), the European
          Union's General Data Privacy Regulation (GDPR) and other applicable
          privacy laws, rules and regulations (collectively, the "Privacy
          Laws"). This Privacy Policy applies to personal data collected, used,
          stored, disclosed and/or processed by the Company and is a binding and
          enforceable legal contract between you, an end user of the Site
          (hereinafter referred to as "you").
        </p>
        <p>
          By interacting with us (including use of the Site or any of its
          Services), providing us with your personal data, submitting
          information to us or signing up for any newsletters or services
          offered by us, you shall be deemed to agree and consent to all terms
          and conditions in this Privacy Policy.
        </p>
        <ol>
          <li>
            <h2>Collection of Personal Information</h2>
            <ol>
              <li>
                When you interact with us or use any service we provide, the
                personal data we may collect includes the following:
                <ol>
                  <li>
                    your personal information, including but not limited to your
                    name, email address, mobile number and address
                  </li>
                  <li>
                    information related to your use of social media platforms
                    and services, including but not limited to your username,
                    profile information, posts, messages, connections, groups,
                    servers, and any other data made available to the Company
                    through the social media platform and services
                  </li>
                  <li>
                    your blockchain wallet address, which is a string of
                    alphanumeric characters that serves as a unique identifier
                    to a digital wallet in a particular blockchain network that
                    is used to send and receive digital assets, such as
                    cryptocurrencies, on a blockchain
                  </li>
                  <li>
                    your purchase history and other information relating to
                    transactions made through the Site
                  </li>
                  <li>
                    information about your preferences and interests indicated
                    in your account
                  </li>
                  <li>
                    information about your use of our websites and services,
                    including cookies, IP addresses, browser type, your mobile
                    operating system, statistics on page views and traffic to
                    our website and other diagnostic data
                  </li>
                </ol>
              </li>
              <li>
                We collect personal data in the following ways:
                <ol>
                  <li>
                    when you submit forms relating to any of our products or
                    services
                  </li>
                  <li>
                    when you register for or use any of our services on websites
                    owned or operated by us
                  </li>
                  <li>when you use or purchase our products or services</li>
                  <li>when you request that we contact you</li>
                  <li>
                    when you request to be included in an email or other mailing
                    list
                  </li>
                  <li>
                    when you browse our website (Please see the Cookie Policy in
                    Section 5 for more information)
                  </li>
                  <li>
                    when you submit your personal data to us for any other
                    reason
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h2>Use of Personal Information</h2>
            <ol>
              <li>
                Your personal information is used only for the specific
                purpose(s) for which it was collected. Generally, we collect,
                use and disclose your personal data for the following purposes:
                <ol>
                  <li>
                    providing you with the products or services that were
                    requested
                  </li>
                  <li>
                    managing your account, to provide you access to different
                    functionalities of the Services made available to you as a
                    registered user
                  </li>
                  <li>verifying and carrying out payments</li>
                  <li>providing customer support</li>
                  <li>
                    analyzing and tracking data to determine the effectiveness
                    of our content and to understand the activity of users
                  </li>
                  <li>
                    providing ongoing information about products and services of
                    the Company which may be of interest to you
                  </li>
                  <li>
                    protecting and enforcing our contractual and legal rights
                    and obligations
                  </li>
                  <li>
                    investigating, addressing or defending against fraud, abuse
                    or suspicious activity linked to or associated with you
                  </li>
                  <li>
                    communicating changes to our policies and terms and
                    conditions
                  </li>
                  <li>
                    facilitating business asset transactions (which may include
                    any merger, acquisition or asset sale) involving the Company
                  </li>
                  <li>
                    compliance with any applicable rules, laws and regulations,
                    codes of practice or guidelines or to assist in law
                    enforcement and investigations by the relevant authorities
                  </li>
                </ol>
              </li>
              <li>
                You may unsubscribe from receiving marketing materials at any
                time by clicking on the "unsubscribe" link in the email you
                receive or updating your preferences to indicate through which
                modes of communication and what kind of marketing communications
                you wish to receive.
              </li>
              <li>
                In order for us to provide information about certain products
                and services which may be of interest to you, we may analyze and
                rely on your overall interaction with us (such as but not
                limited to your shopping behavior on the website, your
                newsletter clicks or opening results, your web surfing
                activities, the newsletter types you are subscribed to, and your
                interactions with us).
              </li>
            </ol>
          </li>
          <li>
            <h2>Disclosure of Personal Information</h2>
            <ol>
              <li>
                Your personal information is kept private and confidential. We
                do not sell your personal information to third parties. Subject
                to applicable law, your personal information may be disclosed to
                the following third parties:
                <ol>
                  <li>
                    our related corporations, parent company, subsidiaries and
                    affiliates
                  </li>
                  <li>
                    service providers who provide services to us, such as
                    payment processing, delivery, market research and/or
                    technology service providers
                  </li>
                  <li>
                    affiliates, partners, merchants and agents with whom we may
                    jointly run promotions, contests, surveys, privileges,
                    marketing programmes and other initiatives
                  </li>
                  <li>
                    our professional advisors, such as lawyers and auditors
                  </li>
                  <li>
                    anyone to whom we transfer or may transfer our rights and
                    duties, such as in connection with an acquisition or sale
                    involving the Company or its assets
                  </li>
                  <li>
                    relevant government regulators or law enforcement agencies
                    to comply with laws, rules or regulations imposed on us
                  </li>
                  <li>
                    banks, credit card companies and their respective service
                    providers
                  </li>
                  <li>
                    any other party to whom you authorize us to disclose your
                    personal data
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h2>Protection of Personal Information</h2>
            <p>
              We apply security measures such as encryption technology and
              firewalls in order to prevent the unauthorized use, alteration,
              loss, hacking and disclosure of our customer's personal
              information. However, neither the computer security system nor the
              Internet is entirely secure. You agree not to hold us responsible
              for any loss or damage incurred as a result of any unauthorized
              access or use of the information you submit to or receive from us
              through the Internet. We highly recommend that you should be
              careful to whom you disclose your account details to. The personal
              information you provide is stored on computer systems with limited
              access, encryption, or both.
            </p>
          </li>
          <li>
            <h2>Cookies</h2>
            <p>
              We may use “cookies” to enhance user experience. “Cookies” are
              files that a website or its service provider transfers to your
              mobile device or computer’s hard drive which enable the website’s
              or service provider’s systems to recognise your device or browser
              and capture and remember certain information. We use cookies to
              track information such as the number of pageviews, the number of
              users, frequency of use, duration of use and common entry and exit
              points into the website.
            </p>
            <p>
              If you do not agree to our use of cookies, you may disable cookies
              by configuring your browser or device settings. However, this may
              restrict your use of certain parts of the website.
            </p>
          </li>
          <li>
            <h2>Third Party Sites</h2>
            <p>
              You may find links on the website which direct you to sites and
              services of a third party that may require you to share your
              personal information. You acknowledge that we do not control how
              these sites collect, use and share your personal information and
              you further acknowledge that these third party sites fall outside
              the scope of this Privacy Policy and are governed by their own
              respective privacy policies. We are not responsible for the
              activity or content of any third party site. You are advised to
              read the privacy policies of the respective third party sites you
              visit.
            </p>
          </li>
          <li>
            <h2>Access and Correction Rights</h2>
            <p>
              It is essential that the information you provide us with is true,
              accurate, current and complete. We rely on you to ensure that your
              personal information is accurate. You may notify us of any changes
              to your personal information held by us by sending us an email at{" "}
              <a
                href="mailto:legal@breederdao.io"
                className="text-blue-600 underline"
              >
                legal@breederdao.io
              </a>
              . You may also send us an email at{" "}
              <a
                href="mailto:legal@breederdao.io"
                className="text-blue-600 underline"
              >
                legal@breederdao.io
              </a>{" "}
              to request a copy of the following information: your information
              held by us, the purposes for which we process such information and
              the parties to whom such information has been shared with. You may
              be charged with a reasonable administrative fee for our processing
              of such a request.
            </p>
          </li>
          <li>
            <h2>Specific Disclosures and Notices</h2>
            <ol>
              <li>
                <h3>Specific Notice to California Residents ("CCPA Notice")</h3>
                <p>
                  The California Consumer Privacy Act of 2018 (the "CCPA")
                  requires certain businesses to provide a CCPA Notice to
                  explain how a company collects, uses, and shares Personal Data
                  of California residents and the rights and choices offered
                  regarding the handling of such data or information.
                </p>
                <ol>
                  <li>
                    <h3>Privacy Practices</h3>
                    <p>
                      We will not sell your Personal Data or "personal
                      information" as defined under the CCPA.
                    </p>
                  </li>
                  <li>
                    <h3>Privacy Rights</h3>
                    <p>
                      The CCPA gives individuals the right to request
                      information about how the Company has collected, used, and
                      shared your personal information and gives you the right
                      to request a copy of any information that we may have
                      stored or maintained about you. You may also ask us to
                      delete any personal information that we may have received
                      about you. The CCPA limits these rights, for example, by
                      prohibiting us from providing certain sensitive
                      information in response to access requests and limiting
                      the circumstances under which we must comply with a
                      request for deletion of personal information. We will
                      respond to requests for information, access, and deletion
                      only to the extent that we are able to associate, with a
                      reasonable effort, the information we maintain with the
                      identifying details you provide in your request. If we
                      deny the request, we will communicate this decision to
                      you. You are entitled to exercise the rights described
                      above free from discrimination.
                    </p>
                  </li>
                  <li>
                    <h3>Submitting a Request</h3>
                    <p>
                      You can submit a request for information, access, or
                      deletion to{" "}
                      <a
                        href="mailto:legal@breederdao.io"
                        className="text-blue-600 underline"
                      >
                        legal@breederdao.io
                      </a>
                      .
                    </p>
                  </li>
                  <li>
                    <h3>Identity Verification</h3>
                    <p>
                      The CCPA requires us to collect and verify the identity of
                      any individual submitting a request to access or delete
                      personal information before providing a substantive
                      response.
                    </p>
                  </li>
                  <li>
                    <h3>Authorized Agents</h3>
                    <p>
                      California residents can designate an "authorized agent"
                      to submit requests on their behalf. We will require the
                      authorized agent to have a written authorization
                      confirming their authority.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <h3>
                  Additional Disclosures for European Union Data Subjects or
                  Users
                </h3>
                <ol>
                  <li>
                    We will process your Personal Data for the purposes
                    described above, as applicable. Our justifications and bases
                    for processing your Personal Data include: (1) you have
                    given consent to the process to us or our Service provides
                    for one or more specific purposes; (2) processing is
                    necessary for the performance of a contract with you; (3)
                    processing is necessary for compliance with a legal
                    obligation; and/or (4) processing is necessary for any
                    legitimate interests pursued by us or a third party, and
                    your interests and fundamental rights and freedoms do not
                    override those interests.
                  </li>
                  <li>
                    Your rights under the GDPR include the right to: (1) request
                    access and obtain a copy of your Personal Data; (2) request
                    rectification or deletion of your personal data; (3) object
                    to or restrict the processing of your Personal Data; and (4)
                    request portability of your Personal Data. Additionally, you
                    may withdraw your consent to our collection at any time.
                    Nevertheless, we cannot edit or delete information that is
                    stored on a particular blockchain. Information such as your
                    transaction data, blockchain wallet address, and assets held
                    by your address that may be related to the data we collect
                    is beyond our control.
                  </li>
                  <li>
                    To exercise any of your rights under the GDPR, please
                    contact us at{" "}
                    <a
                      href="mailto:legal@breederdao.io"
                      className="text-blue-600 underline"
                    >
                      legal@breederdao.io
                    </a>
                    . We may require additional information from you to process
                    your request. Please note that we may retain information as
                    necessary to fulfill the purpose for which it was collected
                    and may continue to do so even after a data subject request
                    in accordance with our legitimate interests, including to
                    comply with our legal obligations, resolve disputes, prevent
                    fraud, and enforce our agreements.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h2>Withdrawing Consent</h2>
            <p>
              You may opt out of the collection, use and disclosure of your
              personal information for any of the above stated purposes
              (including receiving marketing materials) at any time by emailing
              us at{" "}
              <a
                href="mailto:legal@breederdao.io"
                className="text-blue-600 underline"
              >
                legal@breederdao.io
              </a>
              . We will respond to your request as soon as reasonably possible.
              Please note that for certain purposes, your withdrawal of consent
              may prevent us from continuing to provide you with our products
              and/or services through this website. Kindly also note that
              withdrawing your consent for any purposes stated above does not
              affect our right to collect, use or disclose personal information
              where consent is not required for such collection, usage or
              disclosure under applicable laws.
            </p>
          </li>
          <li>
            <h2>Changes to Our Privacy Policy</h2>
            <p>
              We reserve the right to make changes/updates to this Privacy
              Policy without giving you prior notice. You are responsible for
              keeping track of the changes made to the Privacy Policy. Your
              continued access or use of the Site or the Services shall
              constitute your agreement to be bound by any such changes to this
              Privacy Policy.
            </p>
          </li>
          <li>
            <h2>Contact Information</h2>
            <p>
              If you have any questions relating to your personal information or
              our Privacy Policy, please contact us at{" "}
              <a
                href="mailto:legal@breederdao.io"
                className="text-blue-600 underline"
              >
                legal@breederdao.io
              </a>{" "}
              indicating the nature of your query in the subject header.
            </p>
          </li>
        </ol>
      </div>
    </BasicLayout>
  );
};

export default PrivacyPolicy;
