import React, { useEffect, useState } from "react";
import { useForm, Controller, Resolver } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonPrimary from "./ButtonPrimary";
import Checkbox from "./form/Checkbox";
import Modal from "./Modal";
import ReCAPTCHA from "react-google-recaptcha";
import { NewsletterFormData } from "utils/types";
import { cn } from "utils/helpers";
import { Link } from "react-router-dom";

const schema = yup
  .object({
    email: yup
      .string()
      .required("You need to enter your email address.")
      .matches(/@/, 'Your email address should have the "@" symbol')
      .email("Please include a valid domain extension such as .com or .net")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please include a valid domain extension such as .com or .net"
      ),
    termsAccepted: yup
      .bool()
      .oneOf([true], "You must accept the Privacy Policy"),
  })
  .required();

const NewsletterSubscribe = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<NewsletterFormData>({
    resolver: yupResolver(schema) as Resolver<NewsletterFormData>,
    defaultValues: {
      email: "",
      termsAccepted: false,
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  // Function to handle reCAPTCHA token generation and form submission
  const onSubmit = async (data: NewsletterFormData, recaptchaToken: string) => {
    if (!recaptchaToken) {
      console.error("reCAPTCHA verification failed");
      return;
    }

    if (!process.env.REACT_APP_SUBSCRIPTION_API) {
      console.error("Subscription endpoint not set");
      return;
    }

    setIsSubmitting(true);
    console.log("Submitting with token", recaptchaToken);

    try {
      const response = await fetch(process.env.REACT_APP_SUBSCRIPTION_API, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: data.email,
          listIds: [12],
          recaptcha: recaptchaToken, // Pass the token along with the form data
        }),
      });

      if (response.ok) {
        reset();
        setShowModal(true);
      } else {
        console.error("Subscription failed");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle form submission, manually trigger reCAPTCHA execution
  const handleFormSubmit = async () => {
    console.log("Form submit triggered, executing reCAPTCHA...");
    try {
      const token = await recaptchaRef.current?.execute(); // Trigger reCAPTCHA when the form is submitted
      if (token) {
        console.log("reCAPTCHA token:", token);
        handleSubmit((data) => onSubmit(data, token))(); // Submit form after receiving the token
      } else {
        console.error("reCAPTCHA token not received");
      }
    } catch (error) {
      console.error("reCAPTCHA execution error:", error);
    }
  };

  return (
    <>
      <Modal show={showModal} setShowModal={setShowModal}>
        <p className="font-big-shoulders-display font-extrabold leading-[1.1] text-[2rem] lg:text-[2.5rem] text-[#FF1571] mb-5 text-balance mt-2">
          Thanks for joining Sovrun!
        </p>
        <p className="leading-[1.4] text-[#616773] mb-7">
          Stay tuned for the latest updates and news on everything Sovrun,
          straight to your inbox.
        </p>
        <button
          type="button"
          aria-label="Close dialog"
          className="bg-[#0A0C0F] text-white px-8 py-4 rounded-lg w-full"
          style={{
            clipPath: "polygon(100% 0, 100% 80%, 97% 100%, 0 100%, 0 0)",
          }}
          onClick={() => {
            setShowModal(false);
          }}
        >
          <span className="text-xl font-semibold">Close</span>
        </button>
      </Modal>

      <form onSubmit={(e) => e.preventDefault()}>
        <div
          className={cn(`w-full md:w-[33.75rem] h-[4.3125rem] block md:flex `, {
            "mb-6": !errors.email,
            "mb-0": errors.email,
          })}
        >
          <div className="w-full md:w-[19.5625rem] h-full border-l border-t border-b border-r md:border-r-0 border-[#FFF] rounded-[7px] md:rounded-r-none px-[0.625rem] py-[0.5rem] backdrop-blur mb-[18px] md:mb-0">
            <div className="font-ibm-plex-sans font-semibold text-base uppercase">
              Email Address
            </div>
            <div>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="w-full bg-transparent border-none font-ibm-plex-sans font-normal text-sm focus:border-none focus-visible:outline-none"
                    type="email"
                    placeholder="Enter your email address"
                  />
                )}
              />
            </div>
          </div>
          <div
            className={cn(`w-full md:w-[14.0625rem] h-full md:mt-0`, {
              "mt-24": !errors.email,
              "mt-28": errors.email,
              "mt-36": errors.termsAccepted,
            })}
          >
            <ButtonPrimary
              inline="right"
              onClick={handleFormSubmit}
              disabled={isSubmitting}
            >
              Subscribe
            </ButtonPrimary>
          </div>
        </div>

        {errors.email && (
          <p className="text-red-500 text-xs text-center mt-2 mb-6">
            {errors.email.message}
          </p>
        )}

        <label className="flex mb-2 items-center justify-center gap-3">
          <Controller
            name="termsAccepted"
            control={control}
            render={({ field }) => (
              <Checkbox
                checked={field.value ?? false}
                onChange={field.onChange}
              />
            )}
          />
          <span className="font-ibm-plex-sans font-medium text-sm">
            I have read and agree to Sovrun&rsquo;s{" "}
            <Link
              to="/privacy-policy"
              target="_blank"
              className="text-sovrun-pink underline"
            >
              Privacy Policy
            </Link>
          </span>
        </label>
        {errors.termsAccepted && (
          <p className="text-red-500 text-xs mt-1 px-[0.625rem] text-center">
            {errors.termsAccepted.message}
          </p>
        )}

        <div className="opacity-0">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
            size="invisible"
            ref={recaptchaRef}
          />
        </div>
      </form>
    </>
  );
};

export default NewsletterSubscribe;
