// src/pages/Home.tsx
import SovrunLogo from "@assets/icons/sovrun-logo";
import NewsletterSubscribe from "components/NewsletterSubscribe";
import Sovrun3DLogo from "components/Sovrun3DLogo";
import SovrunLogoMobile from "@assets/icons/sovrun-logo--mobile";
import Lottie from "lottie-react";
import pinkDiamondsA from "@assets/lottie/Pink Diamonds 1.json";
import pinkDiamondsB from "@assets/lottie/Pink Diamonds 2.json";
import diamondA from "@assets/lottie/White Diamond and Stroke 1.json";
import diamondB from "@assets/lottie/White Diamond and Stroke 2.json";
import BasicLayoutFooter from "layouts/BasicLayoutFooter";

const Home = () => {
  return (
    <div
      className={`w-screen min-h-[calc(100vh-48px)] md:min-h-[calc(100vh-52px)] md:h-screen relative md:overflow-hidden flex items-center flex-col`}
    >
      <div className="w-full">
        <div
          className="w-full min-h-[calc(100vh-48px)] md:min-h-[calc(100vh-52px)] md:h-full fixed md:absolute top-0 left-0 flex items-center justify-center pointer-events-none bg-center bg-no-repeat bg-cover md:bg-95% xxxl:bg-110%"
          style={{ backgroundImage: "url('/images/bg.svg')" }}
        >
          <div className="absolute left-0 md:left-[5%] top-1/2 -translate-y-1/2">
            <div className="flex">
              <Lottie
                className="w-[160px] hidden md:block"
                animationData={diamondA}
                loop={true}
              />
              <Lottie
                className="w-[100px] md:w-[160px]"
                animationData={pinkDiamondsA}
                loop={true}
              />
            </div>
          </div>

          <div className="absolute right-0 md:right-[5%] top-1/2 -translate-y-1/2">
            <div className="flex">
              <Lottie
                className="w-[100px] md:w-[160px]"
                animationData={pinkDiamondsB}
                loop={true}
              />
              <Lottie
                className="w-[160px] hidden md:block"
                animationData={diamondB}
                loop={true}
              />
            </div>
          </div>
        </div>

        <div className="w-full pt-16 md:py-0 min-h-[calc(100vh-3rem)] md:min-h-[calc(100vh-3.25rem)] h-full flex flex-col items-center justify-end md:justify-center bg-gradient-to-b from-[#050505] to-[#840A3A] px-8 md:px-0">
          <div className="flex items-center justify-center flex-col z-50 xl:-mt-48">
            <div className="mb-10 w-[8.75rem] md:w-[26.375rem]">
              <SovrunLogo className="w-full h-auto hidden md:block" />
              <SovrunLogoMobile className="w-full h-auto block md:hidden" />
            </div>
            <p className="font-ibm-plex-sans font-medium text-base md:text-lg mb-5 md:mb-[10px] text-center md:text-left">
              Subscribe for the latest news and updates about Sovrun
            </p>
            <NewsletterSubscribe />
          </div>

          <div className="md:absolute bottom-0 md:bottom-auto md:top-0 md:left-0 w-full md:h-1/2 pointer-events-none z-0 mt-32 md:mt-0 md:translate-y-[105%]">
            <div className="relative w-full h-[250px] md:h-full flex items-baseline pointer-events-none">
              <Sovrun3DLogo />
            </div>
          </div>
        </div>
      </div>
      <BasicLayoutFooter fixed />
    </div>
  );
};

export default Home;
