import React, { Suspense, useEffect, useState } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Environment, useGLTF, useFBX, Bounds } from "@react-three/drei";

// Paths to your GLB and FBX models
const PLATFORM_MODEL_PATH = "/glb/SovrunPlatform.glb";
const LOGO_MODEL_PATH = "/fbx/SovrunLogoBakedC.fbx";

// Component to load and display the Platform model with rotation
const Platform: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const { scene } = useGLTF(PLATFORM_MODEL_PATH);

  // Rotate the platform clockwise
  useFrame(() => {
    scene.rotation.y -= 0.01;
  });

  // Scale platform based on mobile view
  const scale = isMobile ? 1.2 : 1;
  scene.scale.set(scale, scale, scale);
  scene.position.set(0, isMobile ? 0 : 0.04, 0);

  return <primitive object={scene} />;
};

// Component to load and display the Sovrun Logo model (FBX) with counterclockwise rotation and texture
const SovrunLogo: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const logo = useFBX(LOGO_MODEL_PATH);

  // Position the logo and apply scale based on mobile view
  logo.position.set(0, isMobile ? 0.23 : 0.22, 0);
  const scale = isMobile ? 0.004 : 0.003;
  logo.scale.set(scale, scale, scale);

  // Rotate the logo counterclockwise
  useFrame(() => {
    logo.rotation.y -= 0.01;
  });

  return <primitive object={logo} />;
};

// Component to adjust camera and fit models in the view
const CameraFit = () => {
  const { camera } = useThree();

  useEffect(() => {
    camera.position.set(0, 0, 1);
    camera.lookAt(0, 0, 0);
  }, [camera]);

  return null;
};

// Main component to set up canvas, camera, and lights
const Sovrun3DLogo: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Canvas className="pointer-events-none">
      <CameraFit />
      <ambientLight intensity={0.3} />
      <directionalLight position={[10, 10, 5]} intensity={1} />
      <Suspense fallback={null}>
        <Bounds fit margin={1}>
          {" "}
          {/* Auto-fit the models */}
          <Platform isMobile={isMobile} />
          <SovrunLogo isMobile={isMobile} />
        </Bounds>
        <Environment preset="sunset" />
      </Suspense>
    </Canvas>
  );
};

export default Sovrun3DLogo;
