import { SVGProps } from "react";

const IconRightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="23"
    height="17"
    viewBox="0 0 23 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props} // Spread the props to allow customization
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.914 8.5 20.5 9.914 13.414 17 12 15.586 18.086 9.5H0v-2h18.086L12 1.414 13.414 0 20.5 7.086 21.914 8.5Z"
      fill="#000"
    ></path>
  </svg>
);
export default IconRightArrow;
