import type { ClassValue } from "clsx";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import * as THREE from "three";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

/**
 * Combines and merges class names using `clsx` and `tailwind-merge`.
 *
 * @param inputs - An array of class values, where each can be a string, an object, or an array of strings and objects.
 * @returns A single, merged string of class names, ensuring no duplicate Tailwind classes.
 *
 * `clsx` is used to combine class names conditionally based on truthy values,
 * and `twMerge` removes conflicting Tailwind utility classes.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Adjusts the font size of the root HTML element based on the viewport width.
 *
 * This function dynamically scales the `rem` unit to maintain consistent sizing
 * relative to the screen size, with different base screen widths for mobile
 * and larger viewports.
 *
 * Steps:
 * 1. Gets the width of the viewport to determine the current screen width.
 * 2. Defines a base screen width for mobile (390px) and for tablet/desktop (1440px).
 * 3. Calculates a new `rem` size by scaling from the appropriate base size.
 * 4. Sets the calculated `rem` size as the root font-size on the HTML element,
 *    or limits it to 1rem if the screen width is above 1440px.
 *
 * @param {number} [maxWidth] - Optional maximum screen width at which to stop scaling.
 */
export function adjustFontSize(maxWidth?: number): void {
  // Get the width of the viewport
  const screenWidth = window.innerWidth;

  // Define base screen width based on device type
  const baseScreenWidth = screenWidth < 768 ? 390 : 1440; // Mobile: 390px, Tablet/Desktop: 1440px
  const baseRemSize = 1; // 1rem = 16px typically

  // Restrict screen width to maxWidth if provided
  const effectiveScreenWidth = maxWidth
    ? Math.min(screenWidth, maxWidth)
    : screenWidth;

  // If screen width exceeds 1440px, set font-size to 1rem directly
  const newRemSize =
    screenWidth > 1440
      ? baseRemSize
      : (effectiveScreenWidth / baseScreenWidth) * baseRemSize;

  // Set the new rem size as font-size on the html element
  document.documentElement.style.fontSize = `${newRemSize}rem`;
}

export const loadModel = async (file: File): Promise<THREE.Object3D | null> => {
  try {
    const data = await loadFileData(file);
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();

    // Set up the DRACOLoader
    dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
    loader.setDRACOLoader(dracoLoader);

    // Load the GLTF model
    const gltf = await loader.loadAsync(URL.createObjectURL(new Blob([data])));

    // Traverse and modify the loaded model if needed
    gltf.scene.traverse((node) => {
      if (node instanceof THREE.Mesh) {
        // Optionally, modify materials or other properties
        const originalMaterial = node.material;

        // Assign original material's texture if it exists
        if (originalMaterial && originalMaterial.map) {
          node.material = new THREE.MeshStandardMaterial({
            map: originalMaterial.map, // Keep the texture
            color: 0xffffff, // Base color
          });
        } else {
          // Fallback if no texture map
          node.material = new THREE.MeshStandardMaterial({
            color: 0xffffff,
          });
        }

        // Example: scale and position adjustments
        node.scale.set(1, 1, 1);
        node.rotation.set(0, 0, 0);
        node.position.set(0, 0, 0);
      } else if (node instanceof THREE.Light) {
        // If the node is a light, you can modify or log it if needed
        console.log("Found light:", node);
      }
    });

    // Optionally, return the lights found in the GLTF file
    return gltf.scene; // Return the loaded scene
  } catch (error) {
    console.error("Error loading model:", error);
    return null; // Return null in case of an error
  }
};

const loadFileData = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => resolve(reader.result as ArrayBuffer);
    reader.readAsArrayBuffer(file);
  });
};
