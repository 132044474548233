import Logo from "@assets/icons/sovrun-logo--no-sub";

const BasicLayoutHeader = () => (
  <header>
    <div className="container max-w-5xl px-4 md:px-6 mx-auto undefined">
      <div className="relative pb-3 pt-5 md:pb-4 md:pt-6 flex justify-center">
        <div className="absolute top-1/2 left-0 -translate-y-1/2 hidden md:block">
          <div aria-hidden="true">
            <div className="flex items-center">
              <svg
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.87668 0.439365C4.67032 0.521846 4.50323 0.74622 4.50344 0.940978L4.50591 3.23372L4.50793 3.23452L3.04626 4.6994L3.04424 4.69941L0.363708 4.70229L0.00485158 4.70268L0.251878 5.32016C0.334355 5.52652 0.55872 5.69362 0.75347 5.69341L3.04773 5.69094L4.51255 7.15266L4.51255 7.15307L4.51544 9.83371L4.51583 10.1934L5.13328 9.94635C5.33964 9.86387 5.50673 9.6395 5.50652 9.44474L5.50405 7.1516L6.96572 5.68671L9.64625 5.68383L10.0059 5.68344L9.75889 5.06596C9.67641 4.8596 9.45205 4.6925 9.2573 4.69271L6.96465 4.69518L5.50023 3.23345L5.49821 3.23265L5.49532 0.552005L5.49493 0.192328L4.87748 0.439364L4.87668 0.439365Z"
                  fill="#CACDD1"
                ></path>
              </svg>
              <span className="font-mono font-semibold text-[6px] leading-[1.2] uppercase ml-2 mr-3">
                Initiate Sovrun Specimen 2.0A-ZR
              </span>
              <div className="h-[1px] w-10 bg-[#CACDD1]"></div>
            </div>
          </div>
        </div>
        <div>
          <a href="/">
            <Logo width={169.01} height={42.11} />
          </a>
        </div>
      </div>
    </div>
  </header>
);

export default BasicLayoutHeader;
