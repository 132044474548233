import { SVGProps } from "react";

const SovrunLogoNoSub = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="169"
    height="43"
    viewBox="0 0 169 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props} // Spread the props to allow customization
  >
    <g clip-path="url(#clip0_937_19560)">
      <path
        d="M17.6269 9.54181C10.2843 12.4695 4.33154 20.4496 4.33154 27.3647L17.3209 22.1847L19.137 19.6794L19.1807 16.353L19.1807 16.3494C19.1844 16.0944 19.4029 15.8014 19.6725 15.6939L20.4886 15.3681L20.4886 19.2643L22.117 20.273L26.3157 18.5998C33.6583 15.6703 39.6129 7.69016 39.6129 0.775146L17.6288 9.54181L17.6269 9.54181Z"
        fill="#FF1571"
      />
      <path
        d="M21.9769 34.1212C29.3195 31.1935 35.2723 23.2133 35.2723 16.2983L22.2829 21.4783L20.4668 23.9835L20.4231 27.31L20.4231 27.3136C20.4195 27.5684 20.2009 27.8616 19.9313 27.9691L19.1153 28.2949L19.1153 24.3987L17.4868 23.39L13.2882 25.0632C5.94739 27.9927 -0.00720215 35.9709 -0.00720215 42.8878L21.9769 34.1212Z"
        fill="#FF1571"
      />
    </g>
    <path
      d="M56.3996 19.4915C55.9752 19.3546 55.547 19.227 55.1131 19.1067C54.6792 18.9865 54.2912 18.8459 53.9452 18.6831C53.5992 18.5203 53.322 18.3279 53.1098 18.1059C52.8976 17.8839 52.7925 17.6008 52.7925 17.2585C52.7925 16.7627 53.0047 16.3594 53.4291 16.0523C53.8534 15.7434 54.402 15.5898 55.073 15.5898C55.6044 15.5898 56.1511 15.6971 56.7169 15.9117C57.2827 16.1263 57.8486 16.4556 58.4144 16.8996L60.4827 12.972C59.4925 12.4595 58.4564 12.0692 57.3783 11.8046C56.3002 11.5401 55.2641 11.4069 54.274 11.4069C53.213 11.4069 52.2535 11.5604 51.3952 11.8694C50.5369 12.1783 49.8124 12.6094 49.2198 13.1663C48.6272 13.7231 48.1666 14.391 47.8397 15.168C47.5128 15.9469 47.3484 16.8145 47.3484 17.7729C47.3484 18.7312 47.5032 19.523 47.8129 20.1483C48.1226 20.7736 48.5298 21.2916 49.0325 21.7024C49.5371 22.1131 50.1106 22.4424 50.7567 22.6903C51.4028 22.9382 52.0604 23.1657 52.7332 23.3711C53.2991 23.5598 53.7808 23.73 54.1784 23.8854C54.576 24.039 54.899 24.2055 55.1456 24.3849C55.3922 24.5644 55.57 24.7623 55.677 24.9751C55.7841 25.1897 55.8357 25.4413 55.8357 25.7317C55.8357 26.2109 55.6273 26.6383 55.2125 27.0157C54.7958 27.3931 54.165 27.5799 53.3163 27.5799C52.5727 27.5799 51.8214 27.4171 51.0606 27.0915C50.2998 26.7659 49.5314 26.2701 48.7534 25.6022L46.5245 29.6594C48.6827 31.1154 51.0243 31.8424 53.5533 31.8424C54.7729 31.8424 55.8663 31.6833 56.8297 31.367C57.7931 31.0506 58.6074 30.6066 59.2707 30.0312C59.9341 29.4577 60.4387 28.7695 60.7828 27.9647C61.1269 27.16 61.2989 26.2627 61.2989 25.2692C61.2989 23.7966 60.9051 22.6033 60.1176 21.6875C59.3396 20.7718 58.0971 20.0392 56.3996 19.4915Z"
      fill="#FF1571"
    />
    <path
      d="M82.4523 14.3593C81.5047 13.4343 80.3706 12.711 79.0555 12.1894C77.7385 11.6677 76.3034 11.4069 74.75 11.4069C73.1781 11.4069 71.7374 11.6677 70.4315 12.1894C69.1238 12.711 68.0007 13.4343 67.0606 14.3593C66.1204 15.2842 65.3853 16.3664 64.8552 17.6058C64.325 18.8471 64.0609 20.1864 64.0609 21.6237C64.0609 23.0611 64.325 24.4004 64.8552 25.6416C65.3853 26.8829 66.1204 27.9651 67.0606 28.89C68.0007 29.815 69.1238 30.5364 70.4315 31.0599C71.7392 31.5816 73.1781 31.8424 74.75 31.8424C76.3052 31.8424 77.7404 31.5816 79.0555 31.0599C80.3706 30.5383 81.5029 29.815 82.4523 28.89C83.3998 27.9651 84.1387 26.8829 84.6688 25.6416C85.1989 24.4004 85.463 23.0611 85.463 21.6237C85.463 20.1864 85.1971 18.8471 84.6688 17.6058C84.1405 16.3645 83.4017 15.2823 82.4523 14.3593ZM79.774 23.7548C79.484 24.4059 79.0851 24.9701 78.5827 25.4493C78.0784 25.9284 77.4966 26.3002 76.839 26.5666C76.1815 26.833 75.4851 26.9643 74.75 26.9643C74.0148 26.9643 73.3185 26.8311 72.6609 26.5666C72.0034 26.3021 71.4252 25.9302 70.9302 25.4493C70.4352 24.9701 70.0418 24.4059 69.7518 23.7548C69.4618 23.1055 69.3159 22.3933 69.3159 21.6237C69.3159 20.8542 69.4618 20.1438 69.7518 19.4927C70.0418 18.8434 70.4352 18.2773 70.9302 17.7982C71.4252 17.3191 72.0015 16.9472 72.6609 16.6809C73.3185 16.4163 74.0148 16.2831 74.75 16.2831C75.4851 16.2831 76.1815 16.4163 76.839 16.6809C77.4966 16.9472 78.0784 17.3191 78.5827 17.7982C79.0869 18.2773 79.484 18.8415 79.774 19.4927C80.064 20.1438 80.2099 20.8542 80.2099 21.6237C80.2099 22.3933 80.064 23.1036 79.774 23.7548Z"
      fill="#FF1571"
    />
    <path
      d="M96.4266 23.8483L96.4228 23.8385L95.9993 24.9967C95.9442 25.1549 95.8207 25.278 95.665 25.3307L95.2053 25.4831C95.1009 25.5182 95.0021 25.4127 95.0382 25.3053L95.986 22.7037L91.6306 11.4069L86.016 11.4069L94.3715 31.8424L98.4837 31.8424L106.866 11.4069L101.251 11.4069L96.4266 23.8483Z"
      fill="#FF1571"
    />
    <path
      d="M139.812 22.2194C139.812 22.799 139.789 23.3919 139.745 24C139.702 24.6062 139.564 25.1573 139.335 25.6475C139.106 26.1397 138.757 26.5407 138.29 26.8466C137.822 27.1545 137.156 27.3084 136.29 27.3084C135.426 27.3084 134.754 27.1545 134.277 26.8466C133.8 26.5388 133.451 26.1397 133.231 25.6475C133.01 25.1554 132.878 24.6062 132.834 24C132.791 23.3938 132.768 22.8009 132.768 22.2194L132.768 11.4069L127.578 11.4069L127.578 22.9301C127.578 26.0257 128.293 28.2832 129.722 29.7065C131.153 31.1298 133.342 31.8424 136.29 31.8424C139.238 31.8424 141.423 31.1298 142.844 29.7065C144.266 28.2832 144.975 26.0238 144.975 22.9301L144.975 11.4069L139.812 11.4069L139.812 22.2194Z"
      fill="#FF1571"
    />
    <path
      d="M163.852 11.4069L163.852 22.5612L165.532 24.8053C165.635 24.9381 165.671 25.11 165.631 25.2741L165.513 25.7565C165.487 25.8659 165.348 25.8971 165.279 25.8092L163.586 23.5553L154.293 11.4069L149.118 11.4069L149.118 31.8424L154.293 31.8424L154.293 20.6686L152.56 18.3541C152.457 18.2233 152.421 18.0494 152.461 17.8854L152.579 17.403C152.605 17.2916 152.744 17.2623 152.813 17.3502L155.406 20.8034L163.852 31.8424L169.001 31.8424L169.001 11.4069L163.852 11.4069Z"
      fill="#FF1571"
    />
    <path
      d="M125.919 31.9805L119.417 31.9805L114.433 24.0667L114.433 31.9805L109.211 31.9805L109.211 11.4069L117.338 11.4069C118.459 11.4069 119.434 11.5746 120.27 11.9121C121.105 12.2495 121.792 12.708 122.335 13.2912C122.876 13.8725 123.286 14.5474 123.562 15.3101C123.838 16.0748 123.976 16.8921 123.976 17.766C123.976 19.3305 123.606 20.5984 122.869 21.5718C122.131 22.5452 121.044 23.2045 119.605 23.5497L125.919 31.9805ZM114.435 20.6023L115.422 20.6023C116.452 20.6023 117.243 20.3858 117.794 19.9488C118.344 19.5119 118.62 18.8838 118.62 18.0664C118.62 17.2491 118.344 16.619 117.794 16.184C117.243 15.7471 116.452 15.5286 115.422 15.5286L114.435 15.5286L114.435 20.6023Z"
      fill="#FF1571"
    />
    <defs>
      <clipPath id="clip0_937_19560">
        <rect
          width="39.6285"
          height="42.1139"
          fill="white"
          transform="translate(-0.00720215 0.775146)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SovrunLogoNoSub;
