import React from "react";
import BasicLayoutHeader from "./BasicLayoutHeader";
import BasicLayoutFooter from "./BasicLayoutFooter";

interface BasicLayoutProps {
  children: React.ReactNode;
}

const BasicLayout: React.FC<BasicLayoutProps> = ({ children }) => (
  <div className="bg-[#0a0c0f] overflow-hidden">
    <BasicLayoutHeader />
    {children}
    <BasicLayoutFooter />
  </div>
);

export default BasicLayout;
