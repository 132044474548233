import { SVGProps } from "react";

const IconCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="black"
    strokeWidth="5"
    strokeLinecap="butt"
    strokeLinejoin="miter"
    {...props} // Spread the props to allow customization
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
);
export default IconCheck;
