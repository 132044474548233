import { SVGProps } from "react";

const SovrunLogoMobile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="140"
    height="123"
    viewBox="0 0 140 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props} // Spread the props to allow customization
  >
    <path
      d="M2.12555 122.362L0.569824 116.749H1.82556L2.72554 120.649H2.77067L3.76358 116.749H4.83878L5.82903 120.657H5.87682L6.7768 116.749H8.03253L6.47681 122.362H5.35647L4.32109 118.692H4.27861L3.24589 122.362H2.12555Z"
      fill="white"
    />
    <path
      d="M8.72255 122.362V116.749H9.87209V119.065H12.2057V116.749H13.3526V122.362H12.2057V120.043H9.87209V122.362H8.72255Z"
      fill="white"
    />
    <path
      d="M14.3751 122.362V116.749H18.0387V117.727H15.5246V119.065H17.8503V120.043H15.5246V121.383H18.0494V122.362H14.3751Z"
      fill="white"
    />
    <path
      d="M19.0274 122.362V116.749H21.1725C21.5831 116.749 21.9336 116.824 22.2238 116.976C22.5159 117.126 22.738 117.339 22.8902 117.615C23.0442 117.889 23.1212 118.211 23.1212 118.582C23.1212 118.955 23.0433 119.276 22.8875 119.544C22.7318 119.811 22.5061 120.016 22.2106 120.158C21.9168 120.301 21.561 120.372 21.1433 120.372H19.7071V119.418H20.9575C21.177 119.418 21.3592 119.387 21.5044 119.325C21.6495 119.263 21.7575 119.17 21.8283 119.045C21.9008 118.921 21.9371 118.767 21.9371 118.582C21.9371 118.396 21.9008 118.239 21.8283 118.111C21.7575 117.983 21.6486 117.886 21.5017 117.82C21.3566 117.753 21.1734 117.719 20.9522 117.719H20.177V122.362H19.0274ZM21.9637 119.807L23.315 122.362H22.046L20.7239 119.807H21.9637Z"
      fill="white"
    />
    <path
      d="M24.0083 122.362V116.749H27.672V117.727H25.1578V119.065H27.4835V120.043H25.1578V121.383H27.6826V122.362H24.0083Z"
      fill="white"
    />
    <path
      d="M34.0709 118.563C34.0338 118.43 33.9816 118.312 33.9143 118.21C33.847 118.105 33.7647 118.018 33.6674 117.946C33.5718 117.873 33.4621 117.818 33.3382 117.779C33.2161 117.741 33.0807 117.722 32.932 117.722C32.6541 117.722 32.4099 117.793 32.1993 117.935C31.9904 118.078 31.8276 118.285 31.7108 118.558C31.594 118.828 31.5356 119.159 31.5356 119.55C31.5356 119.941 31.5931 120.273 31.7081 120.547C31.8232 120.822 31.986 121.031 32.1966 121.175C32.4072 121.318 32.6559 121.389 32.9426 121.389C33.2028 121.389 33.4249 121.341 33.609 121.246C33.7948 121.15 33.9364 121.013 34.0338 120.838C34.1329 120.663 34.1824 120.455 34.1824 120.216L34.4161 120.251H33.0143V119.358H35.2895V120.065C35.2895 120.558 35.1886 120.982 34.9868 121.337C34.7851 121.689 34.5072 121.962 34.1532 122.154C33.7993 122.344 33.394 122.439 32.9373 122.439C32.4276 122.439 31.9798 122.323 31.594 122.091C31.2081 121.857 30.9073 121.525 30.6913 121.096C30.4772 120.664 30.3701 120.153 30.3701 119.561C30.3701 119.106 30.4338 118.7 30.5613 118.344C30.6905 117.986 30.871 117.682 31.1028 117.434C31.3347 117.185 31.6046 116.996 31.9126 116.866C32.2205 116.737 32.5541 116.672 32.9134 116.672C33.2214 116.672 33.5081 116.718 33.7736 116.812C34.0391 116.903 34.2745 117.033 34.4798 117.201C34.6868 117.369 34.8559 117.569 34.9868 117.801C35.1178 118.031 35.2019 118.285 35.2391 118.563H34.0709Z"
      fill="white"
    />
    <path
      d="M36.997 122.362H35.7651L37.6421 116.749H39.1235L40.9978 122.362H39.766L38.404 118.031H38.3616L36.997 122.362ZM36.92 120.156H39.8297V121.082H36.92V120.156Z"
      fill="white"
    />
    <path
      d="M41.727 116.749H43.1446L44.642 120.52H44.7057L46.203 116.749H47.6207V122.362H46.5056V118.708H46.4605L45.0535 122.335H44.2942L42.8871 118.695H42.842V122.362H41.727V116.749Z"
      fill="white"
    />
    <path d="M49.7921 116.749V122.362H48.6425V116.749H49.7921Z" fill="white" />
    <path
      d="M55.3603 116.749V122.362H54.3674L52.002 118.829H51.9622V122.362H50.8126V116.749H51.8214L54.1683 120.279H54.2161V116.749H55.3603Z"
      fill="white"
    />
    <path
      d="M59.9901 118.563C59.9529 118.43 59.9007 118.312 59.8335 118.21C59.7662 118.105 59.6839 118.018 59.5866 117.946C59.491 117.873 59.3813 117.818 59.2574 117.779C59.1352 117.741 58.9999 117.722 58.8512 117.722C58.5733 117.722 58.3291 117.793 58.1184 117.935C57.9096 118.078 57.7468 118.285 57.63 118.558C57.5131 118.828 57.4547 119.159 57.4547 119.55C57.4547 119.941 57.5123 120.273 57.6273 120.547C57.7424 120.822 57.9052 121.031 58.1158 121.175C58.3264 121.318 58.5751 121.389 58.8618 121.389C59.122 121.389 59.3441 121.341 59.5282 121.246C59.714 121.15 59.8556 121.013 59.9529 120.838C60.052 120.663 60.1016 120.455 60.1016 120.216L60.3352 120.251H58.9335V119.358H61.2087V120.065C61.2087 120.558 61.1078 120.982 60.906 121.337C60.7042 121.689 60.4264 121.962 60.0724 122.154C59.7184 122.344 59.3131 122.439 58.8565 122.439C58.3468 122.439 57.899 122.323 57.5131 122.091C57.1273 121.857 56.8264 121.525 56.6105 121.096C56.3964 120.664 56.2893 120.153 56.2893 119.561C56.2893 119.106 56.353 118.7 56.4804 118.344C56.6096 117.986 56.7901 117.682 57.022 117.434C57.2539 117.185 57.5238 116.996 57.8317 116.866C58.1397 116.737 58.4733 116.672 58.8326 116.672C59.1406 116.672 59.4273 116.718 59.6928 116.812C59.9582 116.903 60.1936 117.033 60.3989 117.201C60.606 117.369 60.775 117.569 60.906 117.801C61.037 118.031 61.1211 118.285 61.1582 118.563H59.9901Z"
      fill="white"
    />
    <path
      d="M63.952 122.362V116.749H65.1015V119.224H65.1732L67.1298 116.749H68.5076L66.49 119.262L68.5315 122.362H67.1563L65.667 120.054L65.1015 120.767V122.362H63.952Z"
      fill="white"
    />
    <path
      d="M73.7288 116.749V122.362H72.7359L70.3704 118.829H70.3306V122.362H69.181V116.749H70.1899L72.5367 120.279H72.5845V116.749H73.7288Z"
      fill="white"
    />
    <path
      d="M79.7497 119.555C79.7497 120.167 79.6373 120.688 79.4125 121.118C79.1895 121.547 78.8851 121.875 78.4992 122.102C78.1152 122.326 77.6833 122.439 77.2037 122.439C76.7205 122.439 76.2869 122.325 75.9028 122.099C75.5188 121.872 75.2152 121.544 74.9922 121.115C74.7692 120.685 74.6577 120.166 74.6577 119.555C74.6577 118.943 74.7692 118.422 74.9922 117.993C75.2152 117.564 75.5188 117.236 75.9028 117.012C76.2869 116.785 76.7205 116.672 77.2037 116.672C77.6833 116.672 78.1152 116.785 78.4992 117.012C78.8851 117.236 79.1895 117.564 79.4125 117.993C79.6373 118.422 79.7497 118.943 79.7497 119.555ZM78.5842 119.555C78.5842 119.159 78.5267 118.824 78.4116 118.552C78.2984 118.28 78.1382 118.073 77.9311 117.933C77.724 117.792 77.4816 117.722 77.2037 117.722C76.9258 117.722 76.6833 117.792 76.4763 117.933C76.2692 118.073 76.1081 118.28 75.9931 118.552C75.8798 118.824 75.8232 119.159 75.8232 119.555C75.8232 119.952 75.8798 120.286 75.9931 120.558C76.1081 120.831 76.2692 121.037 76.4763 121.178C76.6833 121.319 76.9258 121.389 77.2037 121.389C77.4816 121.389 77.724 121.319 77.9311 121.178C78.1382 121.037 78.2984 120.831 78.4116 120.558C78.5267 120.286 78.5842 119.952 78.5842 119.555Z"
      fill="white"
    />
    <path
      d="M81.6954 122.362L80.1397 116.749H81.3954L82.2954 120.649H82.3405L83.3334 116.749H84.4086L85.3989 120.657H85.4467L86.3466 116.749H87.6024L86.0467 122.362H84.9263L83.8909 118.692H83.8485L82.8157 122.362H81.6954Z"
      fill="white"
    />
    <path
      d="M91.1908 118.363C91.1696 118.142 91.0784 117.97 90.9174 117.848C90.7563 117.725 90.5377 117.664 90.2616 117.664C90.074 117.664 89.9156 117.691 89.7864 117.746C89.6572 117.799 89.5581 117.873 89.4891 117.968C89.4218 118.063 89.3882 118.171 89.3882 118.292C89.3846 118.392 89.405 118.48 89.4492 118.555C89.4953 118.63 89.5581 118.695 89.6377 118.749C89.7174 118.802 89.8094 118.849 89.9138 118.889C90.0183 118.928 90.1298 118.96 90.2483 118.988L90.7368 119.109C90.974 119.163 91.1917 119.236 91.3899 119.328C91.5881 119.419 91.7598 119.532 91.905 119.665C92.0501 119.798 92.1625 119.955 92.2421 120.136C92.3235 120.317 92.3651 120.525 92.3669 120.759C92.3651 121.102 92.2802 121.4 92.112 121.652C91.9457 121.902 91.7049 122.097 91.3899 122.236C91.0766 122.373 90.6988 122.441 90.2563 122.441C89.8174 122.441 89.4351 122.372 89.1094 122.233C88.7855 122.094 88.5324 121.889 88.3501 121.616C88.1696 121.342 88.0749 121.003 88.0661 120.6H89.1784C89.1908 120.788 89.243 120.945 89.3351 121.071C89.4289 121.195 89.5537 121.289 89.7094 121.353C89.8669 121.415 90.0448 121.446 90.243 121.446C90.4377 121.446 90.6067 121.417 90.7501 121.359C90.8952 121.3 91.0076 121.219 91.0873 121.115C91.1669 121.011 91.2067 120.891 91.2067 120.756C91.2067 120.63 91.1704 120.524 91.0979 120.438C91.0271 120.352 90.9227 120.279 90.7846 120.219C90.6483 120.158 90.4811 120.103 90.2829 120.054L89.6908 119.901C89.2324 119.785 88.8705 119.606 88.605 119.361C88.3395 119.116 88.2077 118.786 88.2094 118.371C88.2077 118.031 88.2953 117.734 88.4723 117.48C88.651 117.226 88.8961 117.028 89.2076 116.886C89.5191 116.743 89.8731 116.672 90.2696 116.672C90.6731 116.672 91.0253 116.743 91.3262 116.886C91.6288 117.028 91.8642 117.226 92.0324 117.48C92.2005 117.734 92.2872 118.029 92.2926 118.363H91.1908Z"
      fill="white"
    />
    <path
      d="M99.5676 116.749V122.362H98.5747L96.2093 118.829H96.1694V122.362H95.0199V116.749H96.0287L98.3756 120.279H98.4234V116.749H99.5676Z"
      fill="white"
    />
    <path
      d="M105.589 119.555C105.589 120.167 105.476 120.688 105.251 121.118C105.028 121.547 104.724 121.875 104.338 122.102C103.954 122.326 103.522 122.439 103.043 122.439C102.559 122.439 102.126 122.325 101.742 122.099C101.358 121.872 101.054 121.544 100.831 121.115C100.608 120.685 100.497 120.166 100.497 119.555C100.497 118.943 100.608 118.422 100.831 117.993C101.054 117.564 101.358 117.236 101.742 117.012C102.126 116.785 102.559 116.672 103.043 116.672C103.522 116.672 103.954 116.785 104.338 117.012C104.724 117.236 105.028 117.564 105.251 117.993C105.476 118.422 105.589 118.943 105.589 119.555ZM104.423 119.555C104.423 119.159 104.366 118.824 104.25 118.552C104.137 118.28 103.977 118.073 103.77 117.933C103.563 117.792 103.32 117.722 103.043 117.722C102.765 117.722 102.522 117.792 102.315 117.933C102.108 118.073 101.947 118.28 101.832 118.552C101.719 118.824 101.662 119.159 101.662 119.555C101.662 119.952 101.719 120.286 101.832 120.558C101.947 120.831 102.108 121.037 102.315 121.178C102.522 121.319 102.765 121.389 103.043 121.389C103.32 121.389 103.563 121.319 103.77 121.178C103.977 121.037 104.137 120.831 104.25 120.558C104.366 120.286 104.423 119.952 104.423 119.555Z"
      fill="white"
    />
    <path
      d="M108.32 122.362V116.749H110.497C110.897 116.749 111.23 116.81 111.498 116.932C111.765 117.055 111.966 117.225 112.1 117.442C112.235 117.658 112.302 117.906 112.302 118.188C112.302 118.407 112.26 118.6 112.175 118.766C112.09 118.93 111.973 119.066 111.824 119.172C111.677 119.276 111.509 119.35 111.32 119.394V119.448C111.527 119.458 111.721 119.518 111.901 119.629C112.084 119.741 112.231 119.897 112.345 120.098C112.458 120.297 112.514 120.535 112.514 120.811C112.514 121.108 112.443 121.374 112.299 121.608C112.158 121.84 111.948 122.024 111.67 122.159C111.392 122.294 111.05 122.362 110.643 122.362H108.32ZM109.469 121.392H110.407C110.727 121.392 110.961 121.329 111.107 121.203C111.254 121.075 111.328 120.905 111.328 120.693C111.328 120.537 111.291 120.4 111.219 120.282C111.146 120.163 111.043 120.07 110.908 120.002C110.776 119.934 110.617 119.901 110.433 119.901H109.469V121.392ZM109.469 119.098H110.322C110.479 119.098 110.619 119.069 110.741 119.013C110.865 118.954 110.962 118.872 111.033 118.766C111.106 118.66 111.142 118.533 111.142 118.385C111.142 118.182 111.072 118.019 110.932 117.894C110.794 117.77 110.598 117.708 110.343 117.708H109.469V119.098Z"
      fill="white"
    />
    <path
      d="M118.334 119.555C118.334 120.167 118.221 120.688 117.996 121.118C117.773 121.547 117.469 121.875 117.083 122.102C116.699 122.326 116.267 122.439 115.788 122.439C115.304 122.439 114.871 122.325 114.487 122.099C114.103 121.872 113.799 121.544 113.576 121.115C113.353 120.685 113.242 120.166 113.242 119.555C113.242 118.943 113.353 118.422 113.576 117.993C113.799 117.564 114.103 117.236 114.487 117.012C114.871 116.785 115.304 116.672 115.788 116.672C116.267 116.672 116.699 116.785 117.083 117.012C117.469 117.236 117.773 117.564 117.996 117.993C118.221 118.422 118.334 118.943 118.334 119.555ZM117.168 119.555C117.168 119.159 117.111 118.824 116.996 118.552C116.882 118.28 116.722 118.073 116.515 117.933C116.308 117.792 116.065 117.722 115.788 117.722C115.51 117.722 115.267 117.792 115.06 117.933C114.853 118.073 114.692 118.28 114.577 118.552C114.464 118.824 114.407 119.159 114.407 119.555C114.407 119.952 114.464 120.286 114.577 120.558C114.692 120.831 114.853 121.037 115.06 121.178C115.267 121.319 115.51 121.389 115.788 121.389C116.065 121.389 116.308 121.319 116.515 121.178C116.722 121.037 116.882 120.831 116.996 120.558C117.111 120.286 117.168 119.952 117.168 119.555Z"
      fill="white"
    />
    <path
      d="M122.618 116.749H123.768V120.394C123.768 120.803 123.673 121.161 123.484 121.468C123.296 121.775 123.033 122.015 122.695 122.186C122.357 122.356 121.963 122.441 121.514 122.441C121.063 122.441 120.668 122.356 120.33 122.186C119.992 122.015 119.729 121.775 119.541 121.468C119.354 121.161 119.26 120.803 119.26 120.394V116.749H120.409V120.293C120.409 120.506 120.455 120.696 120.545 120.863C120.637 121.029 120.766 121.16 120.932 121.255C121.099 121.35 121.293 121.397 121.514 121.397C121.737 121.397 121.931 121.35 122.095 121.255C122.262 121.16 122.39 121.029 122.48 120.863C122.572 120.696 122.618 120.506 122.618 120.293V116.749Z"
      fill="white"
    />
    <path
      d="M129.336 116.749V122.362H128.343L125.978 118.829H125.938V122.362H124.788V116.749H125.797L128.144 120.279H128.192V116.749H129.336Z"
      fill="white"
    />
    <path
      d="M132.288 122.362H130.361V116.749H132.304C132.851 116.749 133.322 116.861 133.716 117.086C134.111 117.309 134.414 117.629 134.627 118.048C134.841 118.466 134.948 118.967 134.948 119.55C134.948 120.135 134.841 120.637 134.627 121.057C134.414 121.478 134.109 121.8 133.711 122.025C133.314 122.25 132.84 122.362 132.288 122.362ZM131.51 121.345H132.24C132.58 121.345 132.866 121.283 133.098 121.159C133.331 121.033 133.507 120.838 133.623 120.575C133.742 120.31 133.801 119.968 133.801 119.55C133.801 119.135 133.742 118.796 133.623 118.533C133.507 118.27 133.332 118.076 133.1 117.952C132.868 117.828 132.583 117.765 132.243 117.765H131.51V121.345Z"
      fill="white"
    />
    <path
      d="M138.824 118.363C138.803 118.142 138.712 117.97 138.55 117.848C138.389 117.725 138.171 117.664 137.895 117.664C137.707 117.664 137.549 117.691 137.42 117.746C137.29 117.799 137.191 117.873 137.122 117.968C137.055 118.063 137.021 118.171 137.021 118.292C137.018 118.392 137.038 118.48 137.082 118.555C137.128 118.63 137.191 118.695 137.271 118.749C137.35 118.802 137.443 118.849 137.547 118.889C137.651 118.928 137.763 118.96 137.881 118.988L138.37 119.109C138.607 119.163 138.825 119.236 139.023 119.328C139.221 119.419 139.393 119.532 139.538 119.665C139.683 119.798 139.796 119.955 139.875 120.136C139.957 120.317 139.998 120.525 140 120.759C139.998 121.102 139.913 121.4 139.745 121.652C139.579 121.902 139.338 122.097 139.023 122.236C138.71 122.373 138.332 122.441 137.889 122.441C137.45 122.441 137.068 122.372 136.743 122.233C136.419 122.094 136.166 121.889 135.983 121.616C135.803 121.342 135.708 121.003 135.699 120.6H136.812C136.824 120.788 136.876 120.945 136.968 121.071C137.062 121.195 137.187 121.289 137.343 121.353C137.5 121.415 137.678 121.446 137.876 121.446C138.071 121.446 138.24 121.417 138.383 121.359C138.528 121.3 138.641 121.219 138.72 121.115C138.8 121.011 138.84 120.891 138.84 120.756C138.84 120.63 138.804 120.524 138.731 120.438C138.66 120.352 138.556 120.279 138.418 120.219C138.281 120.158 138.114 120.103 137.916 120.054L137.324 119.901C136.866 119.785 136.504 119.606 136.238 119.361C135.973 119.116 135.841 118.786 135.843 118.371C135.841 118.031 135.928 117.734 136.105 117.48C136.284 117.226 136.529 117.028 136.841 116.886C137.152 116.743 137.506 116.672 137.903 116.672C138.306 116.672 138.658 116.743 138.959 116.886C139.262 117.028 139.497 117.226 139.665 117.48C139.834 117.734 139.92 118.029 139.926 118.363H138.824Z"
      fill="white"
    />
    <path
      d="M66.1087 17.1873C51.7215 22.9276 40.0548 38.5715 40.0548 52.13L65.5071 41.9761L69.0647 37.0654L69.1506 30.473C69.1581 29.7704 69.4571 29.1015 69.9765 28.6231L71.0901 27.5991C71.3293 27.3786 71.7142 27.5505 71.7142 27.8719V36.247L74.9055 38.2239L83.1343 34.9427C97.5215 29.2024 109.188 13.5585 109.188 0L66.1087 17.1873Z"
      fill="#FF1571"
    />
    <path
      d="M66.1072 17.187C51.7197 22.9268 40.0554 38.5718 40.0554 52.1286L65.5076 41.9735L69.0661 37.0619L69.1518 30.5404V30.5333C69.1589 30.0336 69.5872 29.4589 70.1155 29.2483L71.7145 28.6094V36.248L74.9054 38.2255L83.1324 34.9452C97.52 29.2019 109.188 13.5569 109.188 0L66.1108 17.187H66.1072Z"
      fill="#FF1571"
    />
    <path
      d="M74.6307 65.3752C89.0183 59.6355 100.682 43.9905 100.682 30.4336L75.2303 40.5888L71.6718 45.5004L71.5862 52.0218V52.0289C71.579 52.5287 71.1507 53.1033 70.6225 53.3139L69.0234 53.9529V46.3142L65.8326 44.3367L57.6055 47.6171C43.2215 53.3603 31.5537 69.0018 31.5537 82.5622L74.6307 65.3752Z"
      fill="#FF1571"
    />
    <path
      d="M11.8045 97.1959C11.3217 97.0408 10.8346 96.8962 10.3409 96.76C9.84725 96.6238 9.40579 96.4646 9.01216 96.2802C8.61853 96.0958 8.3032 95.8779 8.0618 95.6264C7.8204 95.3749 7.70079 95.0543 7.70079 94.6667C7.70079 94.1051 7.94218 93.6483 8.42498 93.3005C8.90777 92.9505 9.53192 92.7766 10.2953 92.7766C10.8998 92.7766 11.5218 92.8981 12.1655 93.1412C12.8093 93.3843 13.453 93.7573 14.0967 94.2602L16.4498 89.8116C15.3232 89.2311 14.1445 88.789 12.918 88.4893C11.6914 88.1897 10.5127 88.0388 9.38621 88.0388C8.17923 88.0388 7.08752 88.2127 6.11106 88.5627C5.1346 88.9126 4.31037 89.4009 3.63621 90.0316C2.96204 90.6623 2.43793 91.4188 2.06605 92.2988C1.69416 93.181 1.50714 94.1638 1.50714 95.2492C1.50714 96.3347 1.68329 97.2315 2.0356 97.9398C2.3879 98.648 2.85112 99.2347 3.42308 99.6999C3.99721 100.165 4.64964 100.538 5.3847 100.819C6.11976 101.1 6.86787 101.357 7.63338 101.59C8.2771 101.804 8.82513 101.997 9.27748 102.173C9.72982 102.346 10.0974 102.535 10.3779 102.738C10.6584 102.942 10.8607 103.166 10.9825 103.407C11.1043 103.65 11.163 103.935 11.163 104.264C11.163 104.807 10.9259 105.291 10.454 105.718C9.97992 106.145 9.26225 106.357 8.29667 106.357C7.4507 106.357 6.59603 106.173 5.73048 105.804C4.86493 105.435 3.99069 104.874 3.10557 104.117L0.569824 108.712C3.0251 110.362 5.68916 111.185 8.56633 111.185C9.95382 111.185 11.1978 111.005 12.2938 110.646C13.3899 110.288 14.3163 109.785 15.071 109.134C15.8256 108.484 16.3997 107.704 16.7912 106.793C17.1826 105.881 17.3784 104.865 17.3784 103.74C17.3784 102.072 16.9304 100.72 16.0344 99.6832C15.1493 98.6459 13.7357 97.8161 11.8045 97.1959Z"
      fill="#FF1571"
    />
    <path
      d="M41.4832 91.3828C40.3975 90.3352 39.0979 89.516 37.591 88.9251C36.0819 88.3342 34.4374 88.0388 32.6574 88.0388C30.8563 88.0388 29.2054 88.3342 27.709 88.9251C26.2105 89.516 24.9237 90.3352 23.8464 91.3828C22.7691 92.4305 21.9267 93.6562 21.3193 95.06C20.7118 96.4659 20.4092 97.9829 20.4092 99.6109C20.4092 101.239 20.7118 102.756 21.3193 104.162C21.9267 105.568 22.7691 106.793 23.8464 107.841C24.9237 108.889 26.2105 109.706 27.709 110.299C29.2075 110.89 30.8563 111.185 32.6574 111.185C34.4395 111.185 36.084 110.89 37.591 110.299C39.0979 109.708 40.3953 108.889 41.4832 107.841C42.569 106.793 43.4156 105.568 44.023 104.162C44.6305 102.756 44.9331 101.239 44.9331 99.6109C44.9331 97.9829 44.6284 96.4659 44.023 95.06C43.4177 93.6541 42.5711 92.4284 41.4832 91.3828ZM38.4143 102.025C38.082 102.762 37.6248 103.401 37.0492 103.944C36.4713 104.487 35.8047 104.908 35.0512 105.209C34.2977 105.511 33.4998 105.66 32.6574 105.66C31.815 105.66 31.0171 105.509 30.2636 105.209C29.5102 104.91 28.8477 104.489 28.2805 103.944C27.7132 103.401 27.2624 102.762 26.9301 102.025C26.5978 101.289 26.4306 100.482 26.4306 99.6109C26.4306 98.7393 26.5978 97.9347 26.9301 97.1971C27.2624 96.4617 27.7132 95.8206 28.2805 95.2779C28.8477 94.7352 29.508 94.3141 30.2636 94.0124C31.0171 93.7127 31.815 93.5619 32.6574 93.5619C33.4998 93.5619 34.2977 93.7127 35.0512 94.0124C35.8047 94.3141 36.4713 94.7352 37.0492 95.2779C37.627 95.8206 38.082 96.4596 38.4143 97.1971C38.7466 97.9347 38.9138 98.7393 38.9138 99.6109C38.9138 100.482 38.7466 101.287 38.4143 102.025Z"
      fill="#FF1571"
    />
    <path
      d="M57.3168 102.131L57.3125 102.119L56.8311 103.431C56.7685 103.61 56.6282 103.75 56.4511 103.81L55.9287 103.982C55.81 104.022 55.6977 103.902 55.7387 103.781L56.816 100.834L51.8658 88.0388H45.4844L54.981 111.185H59.6548L69.1817 88.0388H62.8002L57.3168 102.131Z"
      fill="#FF1571"
    />
    <path
      d="M106.831 100.286C106.831 100.942 106.804 101.614 106.754 102.302C106.704 102.989 106.548 103.613 106.287 104.168C106.026 104.726 105.627 105.18 105.094 105.527C104.561 105.875 103.802 106.05 102.814 106.05C101.828 106.05 101.062 105.875 100.518 105.527C99.9744 105.178 99.5762 104.726 99.326 104.168C99.0736 103.611 98.9235 102.989 98.8734 102.302C98.8234 101.616 98.7973 100.944 98.7973 100.286V88.0388H92.8789V101.091C92.8789 104.597 93.6949 107.154 95.3246 108.766C96.9565 110.378 99.4522 111.185 102.814 111.185C106.176 111.185 108.667 110.378 110.288 108.766C111.909 107.154 112.719 104.595 112.719 101.091V88.0388H106.831V100.286Z"
      fill="#FF1571"
    />
    <path
      d="M134.147 88.0388V100.673L136.057 103.214C136.173 103.365 136.215 103.56 136.169 103.745L136.035 104.292C136.005 104.416 135.847 104.451 135.769 104.352L133.845 101.799L123.285 88.0388H117.403V111.185H123.285V98.529L121.315 95.9076C121.198 95.7594 121.157 95.5625 121.202 95.3767L121.336 94.8303C121.367 94.7042 121.524 94.671 121.602 94.7705L124.549 98.6817L134.147 111.185H139.998V88.0388H134.147Z"
      fill="#FF1571"
    />
    <path
      d="M90.95 111.461H83.5507L77.8794 102.451V111.461H71.937V88.0388H81.1855C82.4603 88.0388 83.5702 88.2298 84.522 88.614C85.4715 88.9982 86.2542 89.52 86.872 90.184C87.4877 90.8458 87.9539 91.6142 88.2682 92.4824C88.5826 93.353 88.7386 94.2834 88.7386 95.2783C88.7386 97.0593 88.3181 98.5028 87.4791 99.6109C86.6401 100.719 85.4022 101.47 83.7654 101.863L90.95 111.461ZM77.8815 98.5072H79.0045C80.1774 98.5072 81.0771 98.2607 81.7036 97.7633C82.3302 97.2658 82.6445 96.5508 82.6445 95.6203C82.6445 94.6898 82.3302 93.9725 81.7036 93.4773C81.0771 92.9799 80.1774 92.7312 79.0045 92.7312H77.8815V98.5072Z"
      fill="#FF1571"
    />
  </svg>
);
export default SovrunLogoMobile;
