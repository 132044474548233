import { SVGProps } from "react";

const SovrunLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="422"
    height="104"
    viewBox="0 0 422 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props} // Spread the props to allow customization
  >
    <path
      d="M43.9807 21.6502C25.669 28.881 10.8199 48.587 10.8199 65.6661L43.215 52.8756L47.7429 46.6898L47.8523 38.3856C47.8619 37.5006 48.2424 36.658 48.9035 36.0554L50.3209 34.7655C50.6253 34.4878 51.1152 34.7043 51.1152 35.1092V45.6589L55.177 48.1492L65.6504 44.0159C83.9621 36.7851 98.8112 17.0791 98.8112 0L43.9807 21.6502Z"
      fill="#FF1571"
    />
    <path
      d="M43.9791 21.6498C25.667 28.8799 10.8211 48.5873 10.8211 65.6644L43.2159 52.8723L47.7451 46.6854L47.8541 38.4706V38.4616C47.8632 37.8321 48.4083 37.1082 49.0807 36.8429L51.1158 36.0381V45.6602L55.1771 48.1512L65.6483 44.0191C83.9604 36.7845 98.8109 17.0771 98.8109 0L43.9836 21.6498H43.9791Z"
      fill="#FF1571"
    />
    <path
      d="M54.8273 82.3502C73.1393 75.1201 87.9853 55.4127 87.9853 38.3356L55.5904 51.1277L51.0613 57.3146L50.9522 65.5294V65.5384C50.9431 66.1679 50.398 66.8918 49.7257 67.1571L47.6905 67.9619V58.3398L43.6292 55.8488L33.158 59.9809C14.8505 67.2155 0 86.9184 0 104L54.8273 82.3502Z"
      fill="#FF1571"
    />
    <path
      d="M140.846 46.1771C139.786 45.8388 138.717 45.5233 137.633 45.2261C136.55 44.9289 135.581 44.5815 134.717 44.1791C133.853 43.7768 133.161 43.3013 132.631 42.7526C132.101 42.2039 131.839 41.5044 131.839 40.6586C131.839 39.4332 132.369 38.4365 133.428 37.6775C134.488 36.914 135.858 36.5345 137.533 36.5345C138.86 36.5345 140.225 36.7997 141.638 37.3301C143.051 37.8604 144.464 38.6743 145.876 39.7716L151.041 30.065C148.568 28.7985 145.981 27.8338 143.289 27.18C140.597 26.5261 138.01 26.1969 135.538 26.1969C132.889 26.1969 130.493 26.5764 128.35 27.34C126.207 28.1035 124.398 29.1688 122.918 30.545C121.439 31.9212 120.288 33.5718 119.472 35.4921C118.656 37.4169 118.245 39.5613 118.245 41.9296C118.245 44.298 118.632 46.2548 119.405 47.8002C120.178 49.3456 121.195 50.6258 122.45 51.6408C123.711 52.6558 125.142 53.4696 126.756 54.0823C128.369 54.695 130.011 55.2573 131.691 55.7648C133.104 56.2312 134.307 56.6518 135.299 57.0359C136.292 57.4154 137.099 57.8269 137.714 58.2704C138.33 58.7139 138.774 59.2031 139.041 59.7289C139.309 60.2593 139.438 60.8811 139.438 61.5989C139.438 62.7831 138.917 63.8392 137.882 64.7719C136.841 65.7046 135.266 66.1664 133.147 66.1664C131.29 66.1664 129.414 65.7641 127.515 64.9594C125.615 64.1547 123.696 62.9294 121.754 61.2788L116.188 71.3055C121.577 74.9038 127.424 76.7006 133.739 76.7006C136.784 76.7006 139.514 76.3074 141.919 75.5256C144.325 74.7437 146.358 73.6464 148.015 72.2245C149.671 70.8071 150.931 69.1063 151.79 67.1174C152.649 65.1286 153.079 62.9111 153.079 60.4558C153.079 56.8164 152.096 53.8674 150.129 51.6042C148.186 49.341 145.084 47.5305 140.846 46.1771Z"
      fill="#FF1571"
    />
    <path
      d="M205.896 33.4934C203.53 31.2075 200.698 29.42 197.414 28.1308C194.126 26.8415 190.542 26.1969 186.664 26.1969C182.739 26.1969 179.141 26.8415 175.881 28.1308C172.615 29.42 169.811 31.2075 167.464 33.4934C165.116 35.7792 163.281 38.4537 161.957 41.5167C160.633 44.5843 159.974 47.8943 159.974 51.4465C159.974 54.9987 160.633 58.3086 161.957 61.3762C163.281 64.4438 165.116 67.1183 167.464 69.4042C169.811 71.69 172.615 73.473 175.881 74.7668C179.146 76.056 182.739 76.7006 186.664 76.7006C190.547 76.7006 194.131 76.056 197.414 74.7668C200.698 73.4775 203.525 71.69 205.896 69.4042C208.262 67.1183 210.107 64.4438 211.43 61.3762C212.754 58.3086 213.413 54.9987 213.413 51.4465C213.413 47.8943 212.749 44.5843 211.43 41.5167C210.111 38.4491 208.266 35.7747 205.896 33.4934ZM199.208 56.7131C198.484 58.3223 197.488 59.7167 196.234 60.9008C194.975 62.0849 193.522 63.0038 191.88 63.6621C190.238 64.3204 188.499 64.645 186.664 64.645C184.828 64.645 183.089 64.3158 181.447 63.6621C179.806 63.0083 178.362 62.0894 177.126 60.9008C175.89 59.7167 174.908 58.3223 174.184 56.7131C173.459 55.1084 173.095 53.3483 173.095 51.4465C173.095 49.5447 173.459 47.7891 174.184 46.1799C174.908 44.5752 175.89 43.1763 177.126 41.9922C178.362 40.8081 179.801 39.8892 181.447 39.2309C183.089 38.5771 184.828 38.248 186.664 38.248C188.499 38.248 190.238 38.5771 191.88 39.2309C193.522 39.8892 194.975 40.8081 196.234 41.9922C197.493 43.1763 198.484 44.5706 199.208 46.1799C199.932 47.7891 200.297 49.5447 200.297 51.4465C200.297 53.3483 199.932 55.1038 199.208 56.7131Z"
      fill="#FF1571"
    />
    <path
      d="M240.787 56.9442L240.778 56.92L239.72 59.7824C239.583 60.1733 239.274 60.4775 238.885 60.6078L237.738 60.9843C237.477 61.0712 237.23 60.8105 237.32 60.545L239.687 54.1156L228.812 26.1969H214.792L235.656 76.7006H245.923L266.853 26.1969H252.834L240.787 56.9442Z"
      fill="#FF1571"
    />
    <path
      d="M349.117 52.9187C349.117 54.3511 349.06 55.8163 348.951 57.3191C348.841 58.8172 348.498 60.1791 347.926 61.3908C347.355 62.6071 346.483 63.598 345.316 64.3541C344.148 65.1149 342.486 65.4953 340.323 65.4953C338.164 65.4953 336.487 65.1149 335.296 64.3541C334.105 63.5933 333.233 62.6071 332.685 61.3908C332.133 60.1744 331.804 58.8172 331.694 57.3191C331.585 55.821 331.528 54.3558 331.528 52.9187V26.1969H318.569V54.6751C318.569 62.3253 320.355 67.9045 323.924 71.422C327.497 74.9395 332.962 76.7006 340.323 76.7006C347.683 76.7006 353.139 74.9395 356.688 71.422C360.237 67.9045 362.01 62.3206 362.01 54.6751V26.1969H349.117V52.9187Z"
      fill="#FF1571"
    />
    <path
      d="M409.144 26.1969V53.7633L413.339 59.3093C413.596 59.6376 413.686 60.0623 413.586 60.4678L413.292 61.66C413.225 61.9303 412.878 62.0076 412.707 61.7904L408.479 56.2202L385.275 26.1969H372.353V76.7006H385.275V49.086L380.947 43.3662C380.691 43.0428 380.6 42.6132 380.7 42.2077L380.995 41.0155C381.061 40.7403 381.408 40.6679 381.579 40.8851L388.055 49.4191L409.144 76.7006H422V26.1969H409.144Z"
      fill="#FF1571"
    />
    <path
      d="M314.431 77.0418H298.196L285.753 57.4839V77.0418H272.714V26.1969H293.007C295.804 26.1969 298.239 26.6115 300.327 27.4455C302.411 28.2795 304.128 29.4124 305.484 30.8538C306.835 32.2904 307.857 33.9584 308.547 35.8434C309.237 37.7331 309.579 39.753 309.579 41.9127C309.579 45.779 308.657 48.9125 306.816 51.3181C304.975 53.7237 302.259 55.3531 298.667 56.2064L314.431 77.0418ZM285.757 48.9222H288.221C290.795 48.9222 292.769 48.3871 294.144 47.3072C295.518 46.2273 296.208 44.675 296.208 42.6551C296.208 40.6352 295.518 39.0781 294.144 38.0031C292.769 36.9232 290.795 36.3833 288.221 36.3833H285.757V48.9222Z"
      fill="#FF1571"
    />
    <path
      d="M118.425 102.848L115 92.1463H117.765L119.746 99.5824H119.846L122.032 92.1463H124.399L126.58 99.5981H126.685L128.667 92.1463H131.432L128.006 102.848H125.539L123.26 95.8513H123.166L120.892 102.848H118.425Z"
      fill="white"
    />
    <path
      d="M132.951 102.848V92.1463H135.482V96.562H140.62V92.1463H143.145V102.848H140.62V98.4276H135.482V102.848H132.951Z"
      fill="white"
    />
    <path
      d="M145.397 102.848V92.1463H153.463V94.0119H147.928V96.562H153.048V98.4276H147.928V100.983H153.487V102.848H145.397Z"
      fill="white"
    />
    <path
      d="M155.64 102.848V92.1463H160.363C161.268 92.1463 162.039 92.2909 162.678 92.58C163.321 92.8657 163.81 93.2716 164.145 93.7976C164.484 94.3202 164.654 94.9351 164.654 95.6423C164.654 96.353 164.483 96.9644 164.14 97.4765C163.797 97.9851 163.3 98.3753 162.649 98.647C162.002 98.9188 161.219 99.0546 160.299 99.0546H157.137V97.2361H159.89C160.373 97.2361 160.775 97.1769 161.094 97.0584C161.414 96.94 161.651 96.7623 161.807 96.5254C161.967 96.2885 162.047 95.9941 162.047 95.6423C162.047 95.2869 161.967 94.9873 161.807 94.7435C161.651 94.4996 161.412 94.315 161.088 94.1895C160.769 94.0606 160.365 93.9962 159.878 93.9962H158.171V102.848H155.64ZM162.105 97.9781L165.081 102.848H162.287L159.376 97.9781H162.105Z"
      fill="white"
    />
    <path
      d="M166.607 102.848V92.1463H174.674V94.0119H169.138V96.562H174.259V98.4276H169.138V100.983H174.697V102.848H166.607Z"
      fill="white"
    />
    <path
      d="M188.763 95.6057C188.682 95.3514 188.567 95.1267 188.418 94.9316C188.27 94.733 188.089 94.5658 187.875 94.4299C187.664 94.2906 187.423 94.1843 187.15 94.1112C186.881 94.038 186.583 94.0014 186.256 94.0014C185.644 94.0014 185.106 94.1373 184.642 94.409C184.182 94.6808 183.824 95.0762 183.567 95.5952C183.31 96.1108 183.181 96.7414 183.181 97.4869C183.181 98.2325 183.308 98.8665 183.561 99.3891C183.814 99.9116 184.173 100.311 184.636 100.586C185.1 100.857 185.648 100.993 186.279 100.993C186.852 100.993 187.341 100.903 187.746 100.722C188.155 100.537 188.467 100.277 188.682 99.943C188.9 99.6086 189.009 99.2131 189.009 98.7568L189.523 98.8247H186.437V97.1211H191.446V98.4694C191.446 99.41 191.224 100.218 190.78 100.894C190.336 101.566 189.724 102.086 188.945 102.451C188.165 102.814 187.273 102.995 186.267 102.995C185.145 102.995 184.159 102.774 183.31 102.331C182.46 101.885 181.798 101.253 181.322 100.434C180.851 99.612 180.615 98.6366 180.615 97.5078C180.615 96.6404 180.755 95.867 181.036 95.1876C181.32 94.5048 181.718 93.9265 182.228 93.4527C182.739 92.9789 183.333 92.6184 184.011 92.371C184.689 92.1237 185.424 92 186.215 92C186.893 92 187.524 92.0888 188.109 92.2665C188.693 92.4407 189.212 92.688 189.664 93.0086C190.12 93.3291 190.492 93.7105 190.78 94.153C191.068 94.5919 191.254 95.0762 191.335 95.6057H188.763Z"
      fill="white"
    />
    <path
      d="M195.206 102.848H192.494L196.626 92.1463H199.888L204.015 102.848H201.303L198.304 94.5919H198.211L195.206 102.848ZM195.036 98.6418H201.443V100.408H195.036V98.6418Z"
      fill="white"
    />
    <path
      d="M205.621 92.1463H208.742L212.039 99.3368H212.179L215.476 92.1463H218.597V102.848H216.142V95.8827H216.043L212.945 102.796H211.273L208.175 95.8565H208.076V102.848H205.621V92.1463Z"
      fill="white"
    />
    <path d="M223.378 92.1463V102.848H220.847V92.1463H223.378Z" fill="white" />
    <path
      d="M235.639 92.1463V102.848H233.453L228.244 96.1126H228.157V102.848H225.626V92.1463H227.847L233.014 98.877H233.119V92.1463H235.639Z"
      fill="white"
    />
    <path
      d="M245.833 95.6057C245.751 95.3514 245.636 95.1267 245.488 94.9316C245.34 94.733 245.159 94.5658 244.944 94.4299C244.734 94.2906 244.492 94.1843 244.219 94.1112C243.95 94.038 243.652 94.0014 243.325 94.0014C242.713 94.0014 242.175 94.1373 241.712 94.409C241.252 94.6808 240.893 95.0762 240.636 95.5952C240.379 96.1108 240.25 96.7414 240.25 97.4869C240.25 98.2325 240.377 98.8665 240.63 99.3891C240.884 99.9116 241.242 100.311 241.706 100.586C242.17 100.857 242.717 100.993 243.348 100.993C243.921 100.993 244.41 100.903 244.816 100.722C245.225 100.537 245.537 100.277 245.751 99.943C245.969 99.6086 246.078 99.2131 246.078 98.7568L246.593 98.8247H243.506V97.1211H248.516V98.4694C248.516 99.41 248.294 100.218 247.849 100.894C247.405 101.566 246.793 102.086 246.014 102.451C245.234 102.814 244.342 102.995 243.337 102.995C242.214 102.995 241.228 102.774 240.379 102.331C239.529 101.885 238.867 101.253 238.391 100.434C237.92 99.612 237.684 98.6366 237.684 97.5078C237.684 96.6404 237.824 95.867 238.105 95.1876C238.389 94.5048 238.787 93.9265 239.297 93.4527C239.808 92.9789 240.402 92.6184 241.08 92.371C241.758 92.1237 242.493 92 243.284 92C243.962 92 244.593 92.0888 245.178 92.2665C245.763 92.4407 246.281 92.688 246.733 93.0086C247.189 93.3291 247.561 93.7105 247.849 94.153C248.138 94.5919 248.323 95.0762 248.405 95.6057H245.833Z"
      fill="white"
    />
    <path
      d="M254.556 102.848V92.1463H257.087V96.8651H257.245L261.553 92.1463H264.587L260.144 96.9382L264.639 102.848H261.611L258.332 98.4485L257.087 99.8071V102.848H254.556Z"
      fill="white"
    />
    <path
      d="M276.083 92.1463V102.848H273.897L268.688 96.1126H268.601V102.848H266.069V92.1463H268.291L273.458 98.877H273.563V92.1463H276.083Z"
      fill="white"
    />
    <path
      d="M289.34 97.4974C289.34 98.6644 289.092 99.6573 288.597 100.476C288.106 101.295 287.436 101.92 286.586 102.352C285.741 102.781 284.79 102.995 283.734 102.995C282.67 102.995 281.715 102.779 280.87 102.347C280.024 101.915 279.356 101.289 278.865 100.471C278.374 99.6521 278.128 98.661 278.128 97.4974C278.128 96.3303 278.374 95.3375 278.865 94.5188C279.356 93.7001 280.024 93.0765 280.87 92.648C281.715 92.216 282.67 92 283.734 92C284.79 92 285.741 92.216 286.586 92.648C287.436 93.0765 288.106 93.7001 288.597 94.5188C289.092 95.3375 289.34 96.3303 289.34 97.4974ZM286.774 97.4974C286.774 96.7414 286.647 96.1039 286.394 95.5848C286.144 95.0657 285.791 94.6721 285.336 94.4038C284.88 94.1356 284.346 94.0014 283.734 94.0014C283.122 94.0014 282.588 94.1356 282.132 94.4038C281.676 94.6721 281.322 95.0657 281.068 95.5848C280.819 96.1039 280.694 96.7414 280.694 97.4974C280.694 98.2534 280.819 98.8909 281.068 99.41C281.322 99.9291 281.676 100.323 282.132 100.591C282.588 100.859 283.122 100.993 283.734 100.993C284.346 100.993 284.88 100.859 285.336 100.591C285.791 100.323 286.144 99.9291 286.394 99.41C286.647 98.8909 286.774 98.2534 286.774 97.4974Z"
      fill="white"
    />
    <path
      d="M293.624 102.848L290.198 92.1463H292.963L294.945 99.5824H295.044L297.23 92.1463H299.598L301.778 99.5981H301.883L303.865 92.1463H306.63L303.205 102.848H300.738L298.458 95.8513H298.364L296.091 102.848H293.624Z"
      fill="white"
    />
    <path
      d="M314.531 95.2242C314.484 94.8027 314.284 94.4752 313.929 94.2418C313.574 94.0084 313.093 93.8917 312.485 93.8917C312.072 93.8917 311.723 93.9439 311.439 94.0485C311.154 94.1495 310.936 94.2906 310.784 94.4717C310.636 94.6529 310.562 94.8584 310.562 95.0884C310.554 95.28 310.599 95.4472 310.696 95.59C310.798 95.7329 310.936 95.8565 311.111 95.961C311.287 96.0621 311.489 96.1509 311.719 96.2276C311.949 96.3007 312.195 96.3634 312.456 96.4157L313.531 96.6456C314.054 96.7501 314.533 96.8895 314.969 97.0637C315.406 97.2378 315.784 97.4521 316.103 97.7064C316.423 97.9607 316.67 98.2603 316.846 98.6052C317.025 98.9501 317.117 99.3455 317.121 99.7914C317.117 100.446 316.93 101.014 316.559 101.495C316.193 101.972 315.663 102.343 314.969 102.608C314.28 102.869 313.448 103 312.473 103C311.507 103 310.665 102.868 309.948 102.603C309.235 102.338 308.678 101.946 308.276 101.427C307.879 100.905 307.67 100.258 307.651 99.4884H310.1C310.127 99.8472 310.242 100.147 310.445 100.387C310.652 100.624 310.926 100.803 311.269 100.925C311.616 101.044 312.008 101.103 312.444 101.103C312.873 101.103 313.245 101.047 313.561 100.936C313.88 100.824 314.128 100.669 314.303 100.471C314.478 100.272 314.566 100.044 314.566 99.7862C314.566 99.5458 314.486 99.3438 314.326 99.18C314.171 99.0163 313.941 98.877 313.637 98.762C313.337 98.647 312.968 98.5425 312.532 98.4485L311.228 98.1558C310.219 97.9363 309.422 97.5932 308.838 97.1264C308.253 96.6595 307.963 96.0307 307.967 95.2399C307.963 94.5919 308.156 94.0258 308.545 93.5416C308.939 93.0573 309.479 92.6793 310.164 92.4076C310.85 92.1359 311.63 92 312.503 92C313.391 92 314.167 92.1359 314.829 92.4076C315.495 92.6793 316.014 93.0573 316.384 93.5416C316.754 94.0258 316.945 94.5867 316.957 95.2242H314.531Z"
      fill="white"
    />
    <path
      d="M332.975 92.1463V102.848H330.789L325.581 96.1126H325.493V102.848H322.962V92.1463H325.183L330.351 98.877H330.456V92.1463H332.975Z"
      fill="white"
    />
    <path
      d="M346.232 97.4974C346.232 98.6644 345.985 99.6573 345.49 100.476C344.999 101.295 344.328 101.92 343.479 102.352C342.633 102.781 341.682 102.995 340.626 102.995C339.563 102.995 338.608 102.779 337.762 102.347C336.916 101.915 336.248 101.289 335.757 100.471C335.266 99.6521 335.021 98.661 335.021 97.4974C335.021 96.3303 335.266 95.3375 335.757 94.5188C336.248 93.7001 336.916 93.0765 337.762 92.648C338.608 92.216 339.563 92 340.626 92C341.682 92 342.633 92.216 343.479 92.648C344.328 93.0765 344.999 93.7001 345.49 94.5188C345.985 95.3375 346.232 96.3303 346.232 97.4974ZM343.666 97.4974C343.666 96.7414 343.539 96.1039 343.286 95.5848C343.037 95.0657 342.684 94.6721 342.228 94.4038C341.772 94.1356 341.238 94.0014 340.626 94.0014C340.015 94.0014 339.481 94.1356 339.025 94.4038C338.569 94.6721 338.214 95.0657 337.961 95.5848C337.711 96.1039 337.587 96.7414 337.587 97.4974C337.587 98.2534 337.711 98.8909 337.961 99.41C338.214 99.9291 338.569 100.323 339.025 100.591C339.481 100.859 340.015 100.993 340.626 100.993C341.238 100.993 341.772 100.859 342.228 100.591C342.684 100.323 343.037 99.9291 343.286 99.41C343.539 98.8909 343.666 98.2534 343.666 97.4974Z"
      fill="white"
    />
    <path
      d="M352.246 102.848V92.1463H357.039C357.92 92.1463 358.655 92.263 359.243 92.4964C359.831 92.7298 360.274 93.0538 360.57 93.4684C360.866 93.8795 361.014 94.3533 361.014 94.8898C361.014 95.3078 360.921 95.6754 360.734 95.9924C360.547 96.3059 360.289 96.5637 359.962 96.7658C359.639 96.9644 359.268 97.1055 358.851 97.1891V97.2936C359.307 97.311 359.734 97.426 360.132 97.6385C360.533 97.851 360.858 98.1489 361.108 98.5321C361.357 98.9118 361.482 99.3647 361.482 99.8907C361.482 100.459 361.324 100.965 361.008 101.411C360.697 101.854 360.235 102.204 359.623 102.462C359.011 102.72 358.257 102.848 357.361 102.848H352.246ZM354.777 100.999H356.841C357.546 100.999 358.06 100.878 358.384 100.638C358.707 100.394 358.869 100.07 358.869 99.666C358.869 99.3699 358.789 99.1086 358.629 98.8822C358.47 98.6557 358.242 98.4781 357.945 98.3492C357.653 98.2203 357.304 98.1558 356.899 98.1558H354.777V100.999ZM354.777 96.6247H356.654C357 96.6247 357.308 96.5707 357.577 96.4627C357.85 96.3512 358.064 96.1945 358.22 95.9924C358.38 95.7903 358.46 95.5482 358.46 95.266C358.46 94.8793 358.306 94.5675 357.998 94.3306C357.694 94.0937 357.261 93.9753 356.7 93.9753H354.777V96.6247Z"
      fill="white"
    />
    <path
      d="M374.295 97.4974C374.295 98.6644 374.047 99.6573 373.552 100.476C373.061 101.295 372.391 101.92 371.541 102.352C370.696 102.781 369.745 102.995 368.689 102.995C367.625 102.995 366.67 102.779 365.824 102.347C364.979 101.915 364.311 101.289 363.82 100.471C363.328 99.6521 363.083 98.661 363.083 97.4974C363.083 96.3303 363.328 95.3375 363.82 94.5188C364.311 93.7001 364.979 93.0765 365.824 92.648C366.67 92.216 367.625 92 368.689 92C369.745 92 370.696 92.216 371.541 92.648C372.391 93.0765 373.061 93.7001 373.552 94.5188C374.047 95.3375 374.295 96.3303 374.295 97.4974ZM371.728 97.4974C371.728 96.7414 371.602 96.1039 371.348 95.5848C371.099 95.0657 370.746 94.6721 370.29 94.4038C369.834 94.1356 369.301 94.0014 368.689 94.0014C368.077 94.0014 367.543 94.1356 367.087 94.4038C366.631 94.6721 366.277 95.0657 366.023 95.5848C365.774 96.1039 365.649 96.7414 365.649 97.4974C365.649 98.2534 365.774 98.8909 366.023 99.41C366.277 99.9291 366.631 100.323 367.087 100.591C367.543 100.859 368.077 100.993 368.689 100.993C369.301 100.993 369.834 100.859 370.29 100.591C370.746 100.323 371.099 99.9291 371.348 99.41C371.602 98.8909 371.728 98.2534 371.728 97.4974Z"
      fill="white"
    />
    <path
      d="M383.729 92.1463H386.26V99.0964C386.26 99.8768 386.051 100.56 385.634 101.145C385.221 101.73 384.642 102.187 383.898 102.514C383.154 102.838 382.287 103 381.297 103C380.303 103 379.434 102.838 378.69 102.514C377.945 102.187 377.367 101.73 376.954 101.145C376.541 100.56 376.334 99.8768 376.334 99.0964V92.1463H378.865V98.9031C378.865 99.3107 378.965 99.673 379.163 99.99C379.366 100.307 379.65 100.556 380.017 100.737C380.383 100.918 380.81 101.009 381.297 101.009C381.788 101.009 382.215 100.918 382.577 100.737C382.943 100.556 383.226 100.307 383.425 99.99C383.627 99.673 383.729 99.3107 383.729 98.9031V92.1463Z"
      fill="white"
    />
    <path
      d="M398.52 92.1463V102.848H396.334L391.125 96.1126H391.038V102.848H388.507V92.1463H390.728L395.895 98.877H396.001V92.1463H398.52Z"
      fill="white"
    />
    <path
      d="M405.02 102.848H400.776V92.1463H405.055C406.259 92.1463 407.295 92.3606 408.164 92.7891C409.033 93.2141 409.702 93.8255 410.169 94.6233C410.641 95.4211 410.877 96.3756 410.877 97.4869C410.877 98.6017 410.641 99.5598 410.169 100.361C409.702 101.162 409.029 101.777 408.153 102.206C407.28 102.634 406.235 102.848 405.02 102.848ZM403.307 100.91H404.914C405.663 100.91 406.292 100.791 406.802 100.554C407.317 100.314 407.703 99.943 407.96 99.4413C408.221 98.9362 408.351 98.2847 408.351 97.4869C408.351 96.6961 408.221 96.0499 407.96 95.5482C407.703 95.0466 407.319 94.6773 406.808 94.4404C406.298 94.2035 405.668 94.085 404.92 94.085H403.307V100.91Z"
      fill="white"
    />
    <path
      d="M419.41 95.2242C419.364 94.8027 419.163 94.4752 418.808 94.2418C418.454 94.0084 417.973 93.8917 417.365 93.8917C416.951 93.8917 416.603 93.9439 416.318 94.0485C416.034 94.1495 415.816 94.2906 415.664 94.4717C415.515 94.6529 415.441 94.8584 415.441 95.0884C415.434 95.28 415.478 95.4472 415.576 95.59C415.677 95.7329 415.816 95.8565 415.991 95.961C416.166 96.0621 416.369 96.1509 416.599 96.2276C416.829 96.3007 417.074 96.3634 417.335 96.4157L418.411 96.6456C418.933 96.7501 419.412 96.8895 419.849 97.0637C420.285 97.2378 420.663 97.4521 420.983 97.7064C421.302 97.9607 421.55 98.2603 421.725 98.6052C421.905 98.9501 421.996 99.3455 422 99.7914C421.996 100.446 421.809 101.014 421.439 101.495C421.073 101.972 420.543 102.343 419.849 102.608C419.159 102.869 418.327 103 417.353 103C416.386 103 415.545 102.868 414.828 102.603C414.115 102.338 413.557 101.946 413.156 101.427C412.758 100.905 412.55 100.258 412.53 99.4884H414.98C415.007 99.8472 415.122 100.147 415.324 100.387C415.531 100.624 415.806 100.803 416.149 100.925C416.496 101.044 416.887 101.103 417.324 101.103C417.752 101.103 418.124 101.047 418.44 100.936C418.76 100.824 419.007 100.669 419.182 100.471C419.358 100.272 419.446 100.044 419.446 99.7862C419.446 99.5458 419.366 99.3438 419.206 99.18C419.05 99.0163 418.82 98.877 418.516 98.762C418.216 98.647 417.848 98.5425 417.411 98.4485L416.108 98.1558C415.098 97.9363 414.302 97.5932 413.717 97.1264C413.132 96.6595 412.842 96.0307 412.846 95.2399C412.842 94.5919 413.035 94.0258 413.425 93.5416C413.818 93.0573 414.358 92.6793 415.044 92.4076C415.73 92.1359 416.509 92 417.382 92C418.271 92 419.046 92.1359 419.709 92.4076C420.375 92.6793 420.893 93.0573 421.263 93.5416C421.634 94.0258 421.825 94.5867 421.836 95.2242H419.41Z"
      fill="white"
    />
  </svg>
);
export default SovrunLogo;
